var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import FooterMain from './Layout/footer_main';
import technical_support from '../assets/technical_support.png';
import faqsIcon from '../assets/faqs.png';
import documentation from '../assets/documentation.png';
var PageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-height: 100vh;\n  background: #FFFFFF;\n  padding-top: 60px;\n"], ["\n  min-height: 100vh;\n  background: #FFFFFF;\n  padding-top: 60px;\n"])));
var MainContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 0 20px;\n  text-align: center;\n"], ["\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 0 20px;\n  text-align: center;\n"])));
var Title = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 58px;\n  font-weight: 700;\n  line-height: 106%;\n  margin-bottom: 24px;\n  color: #000000;\n"], ["\n  font-family: 'Inter';\n  font-size: 58px;\n  font-weight: 700;\n  line-height: 106%;\n  margin-bottom: 24px;\n  color: #000000;\n"])));
var Subtitle = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 18px;\n  line-height: 22px;\n  text-align: center;\n  color: #000000;\n  max-width: 761px;\n  margin: 0 auto 60px;\n"], ["\n  font-family: 'Inter';\n  font-size: 18px;\n  line-height: 22px;\n  text-align: center;\n  color: #000000;\n  max-width: 761px;\n  margin: 0 auto 60px;\n"])));
var CardsGrid = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(3, 366px);\n  gap: 51px;\n  margin: 0 auto;\n  margin-bottom: 80px;\n"], ["\n  display: grid;\n  grid-template-columns: repeat(3, 366px);\n  gap: 51px;\n  margin: 0 auto;\n  margin-bottom: 80px;\n"])));
var Card = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background: #FFFFFF;\n  box-shadow: 16px 32px 74px rgba(28, 59, 168, 0.1);\n  border-radius: 30px;\n  padding: 40px;\n  text-align: center;\n  height: 309px;\n\n  &:first-child {\n    box-shadow: 16px 32px 74px rgba(79, 28, 168, 0.15);\n  }\n"], ["\n  background: #FFFFFF;\n  box-shadow: 16px 32px 74px rgba(28, 59, 168, 0.1);\n  border-radius: 30px;\n  padding: 40px;\n  text-align: center;\n  height: 309px;\n\n  &:first-child {\n    box-shadow: 16px 32px 74px rgba(79, 28, 168, 0.15);\n  }\n"])));
var IconWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 65px;\n  height: 65px;\n  margin: 0 auto 25px;\n  \n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n"], ["\n  width: 65px;\n  height: 65px;\n  margin: 0 auto 25px;\n  \n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n"])));
var CardTitle = styled.h3(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 110%;\n  color: #A65CE8;\n  margin-bottom: 17px;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 110%;\n  color: #A65CE8;\n  margin-bottom: 17px;\n"])));
var CardText = styled.p(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 16px;\n  line-height: 126%;\n  text-align: center;\n  color: #000000;\n  max-width: 270px;\n  margin: 0 auto;\n"], ["\n  font-family: 'Inter';\n  font-size: 16px;\n  line-height: 126%;\n  text-align: center;\n  color: #000000;\n  max-width: 270px;\n  margin: 0 auto;\n"])));
var FAQSection = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  padding: 0 20px;\n  margin-bottom: 80px;\n"], ["\n  padding: 0 20px;\n  margin-bottom: 80px;\n"])));
var FAQTitle = styled.h2(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 43px;\n  line-height: 52px;\n  color: #000000;\n  margin-bottom: 18px;\n  text-align: left;\n\n  span {\n    color: #A65CE8;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 43px;\n  line-height: 52px;\n  color: #000000;\n  margin-bottom: 18px;\n  text-align: left;\n\n  span {\n    color: #A65CE8;\n  }\n"])));
var FAQUnderline = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  width: 236px;\n  height: 4px;\n  background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n  border-radius: 20px;\n  margin-bottom: 48px;\n"], ["\n  width: 236px;\n  height: 4px;\n  background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n  border-radius: 20px;\n  margin-bottom: 48px;\n"])));
var FAQList = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  max-width: 817px;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n"], ["\n  max-width: 817px;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n"])));
var FAQItem = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  background: #FFFFFF;\n  box-shadow: ", ";\n  border-radius: 30px;\n  padding: 0;\n  cursor: pointer;\n  transition: all 0.3s ease;\n  overflow: hidden;\n  border: 1px solid ", ";\n\n  &:hover {\n    transform: translateY(-2px);\n    box-shadow: 16px 32px 74px rgba(79, 28, 168, 0.2);\n  }\n"], ["\n  background: #FFFFFF;\n  box-shadow: ", ";\n  border-radius: 30px;\n  padding: 0;\n  cursor: pointer;\n  transition: all 0.3s ease;\n  overflow: hidden;\n  border: 1px solid ", ";\n\n  &:hover {\n    transform: translateY(-2px);\n    box-shadow: 16px 32px 74px rgba(79, 28, 168, 0.2);\n  }\n"])), function (props) { return props.isOpen ?
    '16px 32px 74px rgba(79, 28, 168, 0.2)' :
    '16px 32px 74px rgba(79, 28, 168, 0.15)'; }, function (props) { return props.isOpen ? '#A65CE8' : 'transparent'; });
var FAQHeader = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  padding: 29px 48px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background: ", ";\n  transition: background 0.3s ease;\n"], ["\n  padding: 29px 48px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background: ", ";\n  transition: background 0.3s ease;\n"])), function (props) { return props.isOpen ? '#F8F5FF' : '#FFFFFF'; });
var FAQQuestion = styled.h4(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 20px;\n  line-height: 126%;\n  color: #000000;\n  font-weight: 400;\n  margin: 0;\n"], ["\n  font-family: 'Inter';\n  font-size: 20px;\n  line-height: 126%;\n  color: #000000;\n  font-weight: 400;\n  margin: 0;\n"])));
var FAQArrow = styled.span(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  width: 24px;\n  height: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  transform: ", ";\n  transition: all 0.3s ease;\n  color: #A65CE8;\n  opacity: ", ";\n  \n  svg {\n    transition: transform 0.3s ease;\n    transform: scale(", ");\n  }\n"], ["\n  width: 24px;\n  height: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  transform: ", ";\n  transition: all 0.3s ease;\n  color: #A65CE8;\n  opacity: ", ";\n  \n  svg {\n    transition: transform 0.3s ease;\n    transform: scale(", ");\n  }\n"])), function (props) { return props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)'; }, function (props) { return props.isOpen ? '1' : '0.5'; }, function (props) { return props.isOpen ? '1.2' : '1'; });
var FAQContent = styled(motion.div)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  padding: 0 48px 29px;\n  background: ", ";\n"], ["\n  padding: 0 48px 29px;\n  background: ", ";\n"])), function (props) { return props.isOpen ? '#F8F5FF' : '#FFFFFF'; });
var FAQAnswer = styled.p(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 18px;\n  line-height: 160%;\n  color: #000000;\n  opacity: 0.7;\n  margin: 0;\n  padding-top: 16px;\n  border-top: 1px solid rgba(166, 92, 232, 0.1);\n"], ["\n  font-family: 'Inter';\n  font-size: 18px;\n  line-height: 160%;\n  color: #000000;\n  opacity: 0.7;\n  margin: 0;\n  padding-top: 16px;\n  border-top: 1px solid rgba(166, 92, 232, 0.1);\n"])));
var SupportPage = function () {
    var _a = useState(null), openFAQ = _a[0], setOpenFAQ = _a[1];
    var faqs = [
        {
            question: "How do I get started with the platform?",
            answer: "Getting started is simple: First, sign up for an account and choose your plan. Then, follow our quick setup guide to connect your data sources. Our platform will guide you through the initial configuration process, and you'll be ready to start analyzing your data within minutes."
        },
        {
            question: "What data formats do you support?",
            answer: "We support a wide range of data formats including CSV, JSON, Excel (xlsx, xls), SQL databases, and API integrations. Our platform can handle structured, semi-structured, and unstructured data, making it versatile for various business needs."
        },
        {
            question: "How secure is my data?",
            answer: "Your data security is our top priority. We employ enterprise-grade encryption (AES-256), secure data centers, and regular security audits. We're GDPR compliant and SOC 2 certified. All data is encrypted both in transit and at rest, and you maintain full control over your data access permissions."
        },
        {
            question: "What kind of support do you offer?",
            answer: "We provide comprehensive 24/7 technical support through multiple channels: live chat, email, and phone. Our dedicated support team typically responds within 1 hour. Plus, you get access to detailed documentation, video tutorials, and regular training sessions."
        }
    ];
    return (React.createElement(PageContainer, null,
        React.createElement(MainContent, null,
            React.createElement(Title, null, "FAQ & Support Center"),
            React.createElement(Subtitle, null, "We're here to help you make the most of our platform. Whether you have questions about pricing, features, or technical requirements, you'll find the answers you need here."),
            React.createElement(CardsGrid, null,
                React.createElement(Card, null,
                    React.createElement(IconWrapper, null,
                        React.createElement("img", { src: documentation, alt: "Documentation" })),
                    React.createElement(CardTitle, null, "Documentation"),
                    React.createElement(CardText, null, "Access our comprehensive documentation to learn about all features and capabilities of Cognitio Intelligence.")),
                React.createElement(Card, null,
                    React.createElement(IconWrapper, null,
                        React.createElement("img", { src: faqsIcon, alt: "FAQs" })),
                    React.createElement(CardTitle, null, "FAQS"),
                    React.createElement(CardText, null, "Find answers to commonly asked questions about our platform, pricing, features, and technical requirements.")),
                React.createElement(Card, null,
                    React.createElement(IconWrapper, null,
                        React.createElement("img", { src: technical_support, alt: "Technical Support" })),
                    React.createElement(CardTitle, null, "Technical Support"),
                    React.createElement(CardText, null, "Need technical assistance? Our support team is available 24/7 to help you resolve any issues."))),
            React.createElement(FAQSection, null,
                React.createElement(FAQTitle, null,
                    "Frequently ",
                    React.createElement("span", null, "Asked Questions")),
                React.createElement(FAQUnderline, null),
                React.createElement(FAQList, null, faqs.map(function (faq, index) { return (React.createElement(FAQItem, { key: index, isOpen: openFAQ === index, onClick: function () { return setOpenFAQ(openFAQ === index ? null : index); } },
                    React.createElement(FAQHeader, null,
                        React.createElement(FAQQuestion, null, faq.question),
                        React.createElement(FAQArrow, { isOpen: openFAQ === index },
                            React.createElement("svg", { width: "14", height: "8", viewBox: "0 0 14 8", fill: "none" },
                                React.createElement("path", { d: "M1 1L7 7L13 1", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })))),
                    React.createElement(motion.div, { initial: { height: 0, opacity: 0 }, animate: {
                            height: openFAQ === index ? 'auto' : 0,
                            opacity: openFAQ === index ? 1 : 0
                        }, transition: {
                            duration: 0.3,
                            ease: "easeInOut"
                        } },
                        React.createElement(FAQContent, { isOpen: openFAQ === index },
                            React.createElement(FAQAnswer, null, faq.answer))))); })))),
        React.createElement(FooterMain, null)));
};
export default SupportPage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { getCookie } from '../../utils/cookies';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 20px;\n  max-width: 1400px;\n  margin: 0 auto;\n"], ["\n  padding: 20px;\n  max-width: 1400px;\n  margin: 0 auto;\n"])));
var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 24px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 24px;\n"])));
var Title = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #2D3748;\n  margin: 0;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #2D3748;\n  margin: 0;\n"])));
var EditorContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  padding: 20px;\n  margin-bottom: 24px;\n"], ["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  padding: 20px;\n  margin-bottom: 24px;\n"])));
var Form = styled.form(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n"])));
var InputGroup = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"])));
var Label = styled.label(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: 500;\n  color: #4A5568;\n"], ["\n  font-weight: 500;\n  color: #4A5568;\n"])));
var Input = styled.input(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: 8px 12px;\n  border: 1px solid #ECEAFF;\n  border-radius: 6px;\n  font-size: 14px;\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"], ["\n  padding: 8px 12px;\n  border: 1px solid #ECEAFF;\n  border-radius: 6px;\n  font-size: 14px;\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"])));
var TextArea = styled.textarea(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  padding: 12px;\n  border: 1px solid #ECEAFF;\n  border-radius: 6px;\n  font-family: 'Monaco', monospace;\n  font-size: 14px;\n  min-height: 200px;\n  resize: vertical;\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"], ["\n  padding: 12px;\n  border: 1px solid #ECEAFF;\n  border-radius: 6px;\n  font-family: 'Monaco', monospace;\n  font-size: 14px;\n  min-height: 200px;\n  resize: vertical;\n\n  &:focus {\n    outline: none;\n    border-color: #8F4ACF;\n  }\n"])));
var Button = styled.button(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 6px;\n  padding: 10px 20px;\n  font-weight: 500;\n  cursor: pointer;\n  transition: background 0.2s;\n\n  &:hover {\n    background: #7B3AAF;\n  }\n\n  &:disabled {\n    background: #CBD5E0;\n    cursor: not-allowed;\n  }\n"], ["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 6px;\n  padding: 10px 20px;\n  font-weight: 500;\n  cursor: pointer;\n  transition: background 0.2s;\n\n  &:hover {\n    background: #7B3AAF;\n  }\n\n  &:disabled {\n    background: #CBD5E0;\n    cursor: not-allowed;\n  }\n"])));
var SavedQueriesSection = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  margin-top: 32px;\n"], ["\n  margin-top: 32px;\n"])));
var QueryList = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: grid;\n  gap: 16px;\n  margin-top: 16px;\n"], ["\n  display: grid;\n  gap: 16px;\n  margin-top: 16px;\n"])));
var QueryCard = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  padding: 16px;\n"], ["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  padding: 16px;\n"])));
var QueryHeader = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  margin-bottom: 12px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  margin-bottom: 12px;\n"])));
var QueryTitleRow = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"])));
var QueryName = styled.h3(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  margin: 0;\n  font-size: 16px;\n  color: #2D3748;\n  font-weight: 600;\n"], ["\n  margin: 0;\n  font-size: 16px;\n  color: #2D3748;\n  font-weight: 600;\n"])));
var QueryContent = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  display: ", ";\n  margin-top: 12px;\n  padding: 12px;\n  background: #F7FAFC;\n  border-radius: 6px;\n"], ["\n  display: ", ";\n  margin-top: 12px;\n  padding: 12px;\n  background: #F7FAFC;\n  border-radius: 6px;\n"])), function (props) { return props.isExpanded ? 'block' : 'none'; });
var QueryActions = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  display: flex;\n  gap: 8px;\n  margin-top: 12px;\n"], ["\n  display: flex;\n  gap: 8px;\n  margin-top: 12px;\n"])));
var ActionButton = styled.button(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  padding: 8px 16px;\n  border-radius: 6px;\n  border: 1px solid ", ";\n  font-size: 14px;\n  font-weight: 500;\n  cursor: pointer;\n  background: ", ";\n  color: ", ";\n  transition: all 0.2s;\n\n  &:hover {\n    background: ", ";\n    border-color: ", ";\n  }\n"], ["\n  padding: 8px 16px;\n  border-radius: 6px;\n  border: 1px solid ", ";\n  font-size: 14px;\n  font-weight: 500;\n  cursor: pointer;\n  background: ", ";\n  color: ", ";\n  transition: all 0.2s;\n\n  &:hover {\n    background: ", ";\n    border-color: ", ";\n  }\n"])), function (props) { return props.variant === 'danger' ? '#FDA5A5' : '#E9D8FD'; }, function (props) { return props.variant === 'danger' ? '#FFF5F5' : '#FAF5FF'; }, function (props) { return props.variant === 'danger' ? '#E53E3E' : '#805AD5'; }, function (props) { return props.variant === 'danger' ? '#FED7D7' : '#E9D8FD'; }, function (props) { return props.variant === 'danger' ? '#FC8181' : '#D6BCFA'; });
var ExpandButton = styled.button(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  background: transparent;\n  border: none;\n  cursor: pointer;\n  padding: 4px;\n  color: #4A5568;\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  font-size: 14px;\n\n  &:hover {\n    color: #2D3748;\n  }\n"], ["\n  background: transparent;\n  border: none;\n  cursor: pointer;\n  padding: 4px;\n  color: #4A5568;\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  font-size: 14px;\n\n  &:hover {\n    color: #2D3748;\n  }\n"])));
var SQLEditor = function () {
    var datasetName = useParams().datasetName;
    var _a = useState(''), queryName = _a[0], setQueryName = _a[1];
    var _b = useState(''), queryText = _b[0], setQueryText = _b[1];
    var _c = useState([]), savedQueries = _c[0], setSavedQueries = _c[1];
    var _d = useState(false), isSubmitting = _d[0], setIsSubmitting = _d[1];
    var _e = useState(new Set()), expandedQueries = _e[0], setExpandedQueries = _e[1];
    useEffect(function () {
        fetchSavedQueries();
    }, [datasetName]);
    var fetchSavedQueries = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, errorData, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    return [4 /*yield*/, fetch("/api/data-glossary/".concat(datasetName, "/queries/"))];
                case 1:
                    response = _a.sent();
                    if (!!response.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, response.json()];
                case 2:
                    errorData = _a.sent();
                    throw new Error(errorData.error || 'Failed to fetch saved queries');
                case 3: return [4 /*yield*/, response.json()];
                case 4:
                    data = _a.sent();
                    setSavedQueries(data.queries || []);
                    return [3 /*break*/, 6];
                case 5:
                    error_1 = _a.sent();
                    toast.error(error_1 instanceof Error ? error_1.message : 'Failed to load saved queries');
                    console.error('Error fetching saved queries:', error_1);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var headers, response, errorData, data, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    setIsSubmitting(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, 7, 8]);
                    headers = {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': getCookie('csrftoken') || ''
                    };
                    return [4 /*yield*/, fetch("/api/data-glossary/".concat(datasetName, "/queries/"), {
                            method: 'POST',
                            headers: headers,
                            credentials: 'include',
                            body: JSON.stringify({
                                name: queryName,
                                query: queryText
                            })
                        })];
                case 2:
                    response = _a.sent();
                    if (!!response.ok) return [3 /*break*/, 4];
                    return [4 /*yield*/, response.json()];
                case 3:
                    errorData = _a.sent();
                    throw new Error(errorData.error || 'Failed to save query');
                case 4: return [4 /*yield*/, response.json()];
                case 5:
                    data = _a.sent();
                    toast.success('Query saved successfully');
                    setQueryName('');
                    setQueryText('');
                    fetchSavedQueries();
                    return [3 /*break*/, 8];
                case 6:
                    error_2 = _a.sent();
                    toast.error('Failed to save query');
                    console.error('Error saving query:', error_2);
                    return [3 /*break*/, 8];
                case 7:
                    setIsSubmitting(false);
                    return [7 /*endfinally*/];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    var handleDeleteQuery = function (queryId) { return __awaiter(void 0, void 0, void 0, function () {
        var headers, response, error, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!window.confirm('Are you sure you want to delete this query?'))
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    headers = {
                        'X-CSRFToken': getCookie('csrftoken') || ''
                    };
                    return [4 /*yield*/, fetch("/api/data-glossary/".concat(datasetName, "/queries/").concat(queryId, "/"), {
                            method: 'DELETE',
                            headers: headers,
                            credentials: 'include',
                        })];
                case 2:
                    response = _a.sent();
                    if (!!response.ok) return [3 /*break*/, 4];
                    return [4 /*yield*/, response.json()];
                case 3:
                    error = _a.sent();
                    throw new Error(error.error || 'Failed to delete query');
                case 4:
                    toast.success('Query deleted successfully');
                    fetchSavedQueries();
                    return [3 /*break*/, 6];
                case 5:
                    error_3 = _a.sent();
                    toast.error(error_3 instanceof Error ? error_3.message : 'Failed to delete query');
                    console.error('Error deleting query:', error_3);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var handleLoadQuery = function (query) {
        setQueryName(query.name);
        setQueryText(query.query);
    };
    var toggleQueryExpansion = function (queryId) {
        setExpandedQueries(function (prev) {
            var newSet = new Set(prev);
            if (newSet.has(queryId)) {
                newSet.delete(queryId);
            }
            else {
                newSet.add(queryId);
            }
            return newSet;
        });
    };
    return (React.createElement(Container, null,
        React.createElement(Header, null,
            React.createElement(Title, null,
                "SQL Editor - ",
                datasetName)),
        React.createElement(EditorContainer, null,
            React.createElement(Form, { onSubmit: handleSubmit },
                React.createElement(InputGroup, null,
                    React.createElement(Label, { htmlFor: "queryName" }, "Query Name"),
                    React.createElement(Input, { id: "queryName", value: queryName, onChange: function (e) { return setQueryName(e.target.value); }, placeholder: "Enter a name for your query", required: true })),
                React.createElement(InputGroup, null,
                    React.createElement(Label, { htmlFor: "queryText" }, "SQL Query"),
                    React.createElement(TextArea, { id: "queryText", value: queryText, onChange: function (e) { return setQueryText(e.target.value); }, placeholder: "Enter your SQL query here...", required: true })),
                React.createElement(Button, { type: "submit", disabled: isSubmitting }, isSubmitting ? 'Saving...' : 'Save Query'))),
        React.createElement(SavedQueriesSection, null,
            React.createElement(Title, null, "Saved Queries"),
            React.createElement(QueryList, null, savedQueries.map(function (query) { return (React.createElement(QueryCard, { key: query.id },
                React.createElement(QueryHeader, null,
                    React.createElement(QueryTitleRow, null,
                        React.createElement(QueryName, null, query.name),
                        React.createElement(ExpandButton, { onClick: function () { return toggleQueryExpansion(query.id); } },
                            expandedQueries.has(query.id) ? '▼' : '▶',
                            expandedQueries.has(query.id) ? 'Hide' : 'Show',
                            " Query"))),
                React.createElement(QueryContent, { isExpanded: expandedQueries.has(query.id) },
                    React.createElement("pre", null, query.query),
                    React.createElement(QueryActions, null,
                        React.createElement(ActionButton, { onClick: function () { return handleLoadQuery(query); } }, "Load Query"),
                        React.createElement(ActionButton, { variant: "danger", onClick: function () { return handleDeleteQuery(query.id); } }, "Delete Query"))))); })))));
};
export default SQLEditor;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20;

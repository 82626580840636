var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import FooterMain from './Layout/footer_main';
var PageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-height: 100vh;\n  background: #FFFFFF;\n  padding-top: 60px;\n  position: relative;\n  overflow: hidden;\n"], ["\n  min-height: 100vh;\n  background: #FFFFFF;\n  padding-top: 60px;\n  position: relative;\n  overflow: hidden;\n"])));
var MainContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 0 20px;\n"], ["\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 0 20px;\n"])));
var Title = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 58px;\n  font-weight: 700;\n  line-height: 106%;\n  margin-bottom: 24px;\n  color: #000000;\n  text-align: center;\n\n  span {\n    background: linear-gradient(90deg, #A65CE8 0%, #7094F4 100%);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n  }\n"], ["\n  font-family: 'Inter';\n  font-size: 58px;\n  font-weight: 700;\n  line-height: 106%;\n  margin-bottom: 24px;\n  color: #000000;\n  text-align: center;\n\n  span {\n    background: linear-gradient(90deg, #A65CE8 0%, #7094F4 100%);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n  }\n"])));
var Subtitle = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 18px;\n  line-height: 22px;\n  text-align: center;\n  color: #000000;\n  max-width: 761px;\n  margin: 0 auto 60px;\n"], ["\n  font-family: 'Inter';\n  font-size: 18px;\n  line-height: 22px;\n  text-align: center;\n  color: #000000;\n  max-width: 761px;\n  margin: 0 auto 60px;\n"])));
var ContentSection = styled(motion.section)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: rgba(255, 255, 255, 0.6);\n  box-shadow: 16px 32px 74px rgba(28, 59, 168, 0.1);\n  border-radius: 30px;\n  padding: 40px;\n  margin-bottom: 40px;\n  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);\n\n  &:hover {\n    transform: translateY(-5px);\n    box-shadow: 0 30px 60px rgba(28, 59, 168, 0.15);\n  }\n"], ["\n  background: rgba(255, 255, 255, 0.6);\n  box-shadow: 16px 32px 74px rgba(28, 59, 168, 0.1);\n  border-radius: 30px;\n  padding: 40px;\n  margin-bottom: 40px;\n  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);\n\n  &:hover {\n    transform: translateY(-5px);\n    box-shadow: 0 30px 60px rgba(28, 59, 168, 0.15);\n  }\n"])));
var BackgroundDecoration = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  width: 600px;\n  height: 600px;\n  border-radius: 50%;\n  background: radial-gradient(circle, rgba(166, 92, 232, 0.1) 0%, rgba(166, 92, 232, 0) 70%);\n  z-index: -1;\n  \n  &.top-right {\n    top: -200px;\n    right: -200px;\n  }\n  \n  &.bottom-left {\n    bottom: -200px;\n    left: -200px;\n    background: radial-gradient(circle, rgba(112, 148, 244, 0.1) 0%, rgba(112, 148, 244, 0) 70%);\n  }\n"], ["\n  position: absolute;\n  width: 600px;\n  height: 600px;\n  border-radius: 50%;\n  background: radial-gradient(circle, rgba(166, 92, 232, 0.1) 0%, rgba(166, 92, 232, 0) 70%);\n  z-index: -1;\n  \n  &.top-right {\n    top: -200px;\n    right: -200px;\n  }\n  \n  &.bottom-left {\n    bottom: -200px;\n    left: -200px;\n    background: radial-gradient(circle, rgba(112, 148, 244, 0.1) 0%, rgba(112, 148, 244, 0) 70%);\n  }\n"])));
var SectionTitle = styled.h2(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #7094F4;\n  margin-bottom: 20px;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #7094F4;\n  margin-bottom: 20px;\n"])));
var SectionText = styled.p(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 16px;\n  line-height: 1.6;\n  color: #666666;\n  margin-bottom: 20px;\n"], ["\n  font-family: 'Inter';\n  font-size: 16px;\n  line-height: 1.6;\n  color: #666666;\n  margin-bottom: 20px;\n"])));
var PrivacyPolicyPage = function () {
    return (React.createElement(PageContainer, null,
        React.createElement(BackgroundDecoration, { className: "top-right" }),
        React.createElement(BackgroundDecoration, { className: "bottom-left" }),
        React.createElement(MainContent, null,
            React.createElement(Title, null,
                "Privacy ",
                React.createElement("span", null, "Policy")),
            React.createElement(Subtitle, null, "Your privacy is important to us. Please read our policy carefully to understand how we collect, use, and protect your information."),
            [
                {
                    title: "Introduction",
                    content: "Cognitio Intelligence is committed to protecting your privacy. This policy explains how we handle your personal data."
                },
                {
                    title: "Information We Collect",
                    content: "We collect personal data that you provide to us directly, such as when you create an account, and data collected automatically through your interaction with our services."
                },
                {
                    title: "How We Use Your Information",
                    content: "Your information is used to provide and improve our services, personalize your experience, and communicate important updates."
                },
                {
                    title: "Data Security",
                    content: "We implement robust security measures to protect your personal data from unauthorized access, disclosure, or destruction."
                },
                {
                    title: "Your Rights",
                    content: "You have the right to access, correct, or delete your personal data. You can also request to restrict or object to our processing of your data."
                },
                {
                    title: "Changes to Policy",
                    content: "We may update this privacy policy from time to time. Please review this page periodically for any changes."
                },
                {
                    title: "Contact Us",
                    content: "If you have any questions regarding this privacy policy, please contact us at privacy@cognitioi.com."
                }
            ].map(function (section, index) { return (React.createElement(ContentSection, { key: index, initial: { opacity: 0, y: 20 }, whileInView: { opacity: 1, y: 0 }, viewport: { once: true }, transition: { delay: index * 0.1 } },
                React.createElement(SectionTitle, null, section.title),
                React.createElement(SectionText, null, section.content))); })),
        React.createElement(FooterMain, null)));
};
export default PrivacyPolicyPage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;

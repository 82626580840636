var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"], ["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"])));
var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"])));
var UsageSection = styled.section(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"], ["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"])));
var UsageStats = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n  gap: 24px;\n  margin-bottom: 32px;\n"], ["\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n  gap: 24px;\n  margin-bottom: 32px;\n"])));
var StatBox = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: #F8F5FF;\n  border-radius: 12px;\n  padding: 24px;\n"], ["\n  background: #F8F5FF;\n  border-radius: 12px;\n  padding: 24px;\n"])));
var StatLabel = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n  margin-bottom: 8px;\n"], ["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n  margin-bottom: 8px;\n"])));
var StatValue = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #000000;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #000000;\n"])));
var ProgressBarContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 100%;\n  height: 8px;\n  background: #F0F0F0;\n  border-radius: 4px;\n  margin: 16px 0;\n  overflow: hidden;\n"], ["\n  width: 100%;\n  height: 8px;\n  background: #F0F0F0;\n  border-radius: 4px;\n  margin: 16px 0;\n  overflow: hidden;\n"])));
var ProgressBar = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: ", "%;\n  height: 100%;\n  background: ", ";\n  border-radius: 4px;\n  transition: width 0.3s ease;\n"], ["\n  width: ", "%;\n  height: 100%;\n  background: ", ";\n  border-radius: 4px;\n  transition: width 0.3s ease;\n"])), function (props) { return props.width; }, function (props) { return props.width > 90 ? '#EB564F' : '#8F4ACF'; });
var WarningMessage = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background: #FFF5F5;\n  border: 1px solid #FED7D7;\n  border-radius: 8px;\n  padding: 16px;\n  color: #E53E3E;\n  font-family: 'Inter';\n  font-size: 14px;\n  margin-top: 16px;\n  display: ", ";\n"], ["\n  background: #FFF5F5;\n  border: 1px solid #FED7D7;\n  border-radius: 8px;\n  padding: 16px;\n  color: #E53E3E;\n  font-family: 'Inter';\n  font-size: 14px;\n  margin-top: 16px;\n  display: ", ";\n"])), function (props) { return props.hidden ? 'none' : 'block'; });
var UsageHistory = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  margin-top: 32px;\n"], ["\n  margin-top: 32px;\n"])));
var HistoryTitle = styled.h2(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 20px;\n  color: #000000;\n  margin-bottom: 16px;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 20px;\n  color: #000000;\n  margin-bottom: 16px;\n"])));
var HistoryTable = styled.table(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 16px;\n  \n  th, td {\n    padding: 12px;\n    text-align: left;\n    border-bottom: 1px solid #ECEAFF;\n    font-family: 'Inter';\n  }\n  \n  th {\n    font-weight: 600;\n    color: #6B6B6B;\n    font-size: 14px;\n  }\n  \n  td {\n    font-size: 14px;\n    color: #000000;\n  }\n"], ["\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 16px;\n  \n  th, td {\n    padding: 12px;\n    text-align: left;\n    border-bottom: 1px solid #ECEAFF;\n    font-family: 'Inter';\n  }\n  \n  th {\n    font-weight: 600;\n    color: #6B6B6B;\n    font-size: 14px;\n  }\n  \n  td {\n    font-size: 14px;\n    color: #000000;\n  }\n"])));
var UsageLimitations = function () {
    var user = useAuth().user;
    var _a = useState(null), activityData = _a[0], setActivityData = _a[1];
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    useEffect(function () {
        var fetchActivityCounts = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, data, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, 4, 5]);
                        return [4 /*yield*/, fetch('/api/activity-counts/')];
                    case 1:
                        response = _a.sent();
                        if (!response.ok) {
                            throw new Error('Failed to fetch activity counts');
                        }
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        setActivityData(data.activity_data);
                        return [3 /*break*/, 5];
                    case 3:
                        err_1 = _a.sent();
                        setError(err_1 instanceof Error ? err_1.message : 'An error occurred');
                        return [3 /*break*/, 5];
                    case 4:
                        setIsLoading(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        fetchActivityCounts();
    }, []);
    var calculateUsageData = function () {
        if (!(activityData === null || activityData === void 0 ? void 0 : activityData.daily_counts.length))
            return {
                currentUsage: 0,
                totalLimit: 10000,
                dailyUsage: 0,
                dailyLimit: 500,
                monthlyUsage: 0,
                monthlyLimit: 10000,
                history: [],
            };
        var currentDayTotal = activityData.daily_counts[0].total;
        var totalUsage = activityData.daily_counts.reduce(function (sum, day) { return sum + day.total; }, 0);
        return {
            currentUsage: totalUsage,
            totalLimit: 10000,
            dailyUsage: currentDayTotal,
            dailyLimit: 500,
            monthlyUsage: totalUsage,
            monthlyLimit: 10000,
            history: activityData.daily_counts.map(function (day) { return ({
                date: day.date,
                type: 'Total Requests',
                requests: day.total,
            }); }),
        };
    };
    var renderHistoryTable = function () { return (React.createElement(HistoryTable, null,
        React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement("th", null, "Date"),
                React.createElement("th", null, "Activity Type"),
                React.createElement("th", null, "Number of Requests"))),
        React.createElement("tbody", null, activityData === null || activityData === void 0 ? void 0 : activityData.daily_counts.map(function (day, dayIndex) { return (Object.entries(day)
            .filter(function (_a) {
            var key = _a[0];
            return key !== 'date' && key !== 'total';
        })
            .map(function (_a, typeIndex) {
            var type = _a[0], count = _a[1];
            return (React.createElement("tr", { key: "".concat(dayIndex, "-").concat(typeIndex) },
                React.createElement("td", null, new Date(day.date).toLocaleDateString()),
                React.createElement("td", null, type),
                React.createElement("td", null, count)));
        })); })))); };
    if (isLoading) {
        return React.createElement("div", null, "Loading usage data...");
    }
    if (error) {
        return React.createElement("div", null,
            "Error loading usage data: ",
            error);
    }
    var usageData = calculateUsageData();
    var usagePercentage = (usageData.currentUsage / usageData.totalLimit) * 100;
    var dailyPercentage = (usageData.dailyUsage / usageData.dailyLimit) * 100;
    var monthlyPercentage = (usageData.monthlyUsage / usageData.monthlyLimit) * 100;
    return (React.createElement(Container, null,
        React.createElement(Title, null, "Usage & Limitations"),
        React.createElement(UsageSection, null,
            React.createElement(UsageStats, null,
                React.createElement(StatBox, null,
                    React.createElement(StatLabel, null, "Total Usage"),
                    React.createElement(StatValue, null,
                        usageData.currentUsage.toLocaleString(),
                        " / ",
                        usageData.totalLimit.toLocaleString()),
                    React.createElement(ProgressBarContainer, null,
                        React.createElement(ProgressBar, { width: usagePercentage }))),
                React.createElement(StatBox, null,
                    React.createElement(StatLabel, null, "Daily Usage"),
                    React.createElement(StatValue, null,
                        usageData.dailyUsage.toLocaleString(),
                        " / ",
                        usageData.dailyLimit.toLocaleString()),
                    React.createElement(ProgressBarContainer, null,
                        React.createElement(ProgressBar, { width: dailyPercentage }))),
                React.createElement(StatBox, null,
                    React.createElement(StatLabel, null, "Monthly Usage"),
                    React.createElement(StatValue, null,
                        usageData.monthlyUsage.toLocaleString(),
                        " / ",
                        usageData.monthlyLimit.toLocaleString()),
                    React.createElement(ProgressBarContainer, null,
                        React.createElement(ProgressBar, { width: monthlyPercentage })))),
            React.createElement(WarningMessage, { hidden: usagePercentage <= 90 },
                "You have reached ",
                usagePercentage.toFixed(1),
                "% of your usage limit. Please contact an administrator to increase your quota."),
            React.createElement(UsageHistory, null,
                React.createElement(HistoryTitle, null, "Recent Usage History"),
                renderHistoryTable()))));
};
export default UsageLimitations;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;

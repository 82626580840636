var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 680px;\n  height: 656px;\n  background: #FAFAFA;\n  border-radius: 20px;\n  padding: 40px;\n  margin: 100px auto;\n"], ["\n  width: 680px;\n  height: 656px;\n  background: #FAFAFA;\n  border-radius: 20px;\n  padding: 40px;\n  margin: 100px auto;\n"])));
var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 28px;\n  text-align: center;\n  margin-bottom: 40px;\n"], ["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 28px;\n  text-align: center;\n  margin-bottom: 40px;\n"])));
var Input = styled.input(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 422px;\n  height: 52px;\n  background: #FFFFFF;\n  border: 1px solid #F1F1F1;\n  border-radius: 12px;\n  padding: 0 16px;\n  margin-bottom: 16px;\n"], ["\n  width: 422px;\n  height: 52px;\n  background: #FFFFFF;\n  border: 1px solid #F1F1F1;\n  border-radius: 12px;\n  padding: 0 16px;\n  margin-bottom: 16px;\n"])));
var Button = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 422px;\n  height: 52px;\n  background: #000000;\n  border-radius: 12px;\n  color: #FFFFFF;\n  border: none;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 15px;\n  margin-top: 20px;\n\n  &:disabled {\n    opacity: 0.7;\n    cursor: not-allowed;\n  }\n"], ["\n  width: 422px;\n  height: 52px;\n  background: #000000;\n  border-radius: 12px;\n  color: #FFFFFF;\n  border: none;\n  cursor: pointer;\n  font-family: 'Inter';\n  font-size: 15px;\n  margin-top: 20px;\n\n  &:disabled {\n    opacity: 0.7;\n    cursor: not-allowed;\n  }\n"])));
var ErrorMessage = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: red;\n  font-size: 12px;\n  margin-top: 4px;\n"], ["\n  color: red;\n  font-size: 12px;\n  margin-top: 4px;\n"])));
var SuccessMessage = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: green;\n  font-size: 14px;\n  margin-top: 8px;\n  text-align: center;\n"], ["\n  color: green;\n  font-size: 14px;\n  margin-top: 8px;\n  text-align: center;\n"])));
var StyledField = styled(Field)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 422px;\n  height: 52px;\n  background: #FFFFFF;\n  border: 1px solid #F1F1F1;\n  border-radius: 12px;\n  padding: 0 16px;\n  margin-bottom: 16px;\n  font-family: 'Inter';\n  font-size: 15px;\n"], ["\n  width: 422px;\n  height: 52px;\n  background: #FFFFFF;\n  border: 1px solid #F1F1F1;\n  border-radius: 12px;\n  padding: 0 16px;\n  margin-bottom: 16px;\n  font-family: 'Inter';\n  font-size: 15px;\n"])));
var FormGroup = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: relative;\n  width: 422px;\n  margin: 0 auto 20px;\n"], ["\n  position: relative;\n  width: 422px;\n  margin: 0 auto 20px;\n"])));
var ForgotPasswordLink = styled(Link)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: absolute;\n  right: 0;\n  bottom: -25px;\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #8F4ACF;\n  text-decoration: none;\n  &:hover {\n    text-decoration: underline;\n  }\n"], ["\n  position: absolute;\n  right: 0;\n  bottom: -25px;\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #8F4ACF;\n  text-decoration: none;\n  &:hover {\n    text-decoration: underline;\n  }\n"])));
var ButtonContainer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 422px;\n  margin: 20px auto 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"], ["\n  width: 422px;\n  margin: 20px auto 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"])));
var SignUpText = styled.p(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n  text-align: center;\n  margin-top: 20px;\n\n  a {\n    color: #8F4ACF;\n    text-decoration: none;\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n"], ["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n  text-align: center;\n  margin-top: 20px;\n\n  a {\n    color: #8F4ACF;\n    text-decoration: none;\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n"])));
var MFAModal = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n"])));
var MFAContainer = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  width: 400px;\n  background: #FFFFFF;\n  border-radius: 20px;\n  padding: 32px;\n  text-align: center;\n  position: relative;\n\n  h2 {\n    font-family: 'Inter';\n    font-weight: 600;\n    font-size: 24px;\n    margin-bottom: 16px;\n  }\n\n  p {\n    font-family: 'Inter';\n    font-size: 14px;\n    color: #666666;\n    margin-bottom: 24px;\n    line-height: 1.5;\n  }\n"], ["\n  width: 400px;\n  background: #FFFFFF;\n  border-radius: 20px;\n  padding: 32px;\n  text-align: center;\n  position: relative;\n\n  h2 {\n    font-family: 'Inter';\n    font-weight: 600;\n    font-size: 24px;\n    margin-bottom: 16px;\n  }\n\n  p {\n    font-family: 'Inter';\n    font-size: 14px;\n    color: #666666;\n    margin-bottom: 24px;\n    line-height: 1.5;\n  }\n"])));
var CloseButton = styled.button(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  position: absolute;\n  top: 16px;\n  right: 16px;\n  background: none;\n  border: none;\n  font-size: 24px;\n  color: #666666;\n  cursor: pointer;\n  padding: 4px;\n  line-height: 1;\n\n  &:hover {\n    color: #000000;\n  }\n"], ["\n  position: absolute;\n  top: 16px;\n  right: 16px;\n  background: none;\n  border: none;\n  font-size: 24px;\n  color: #666666;\n  cursor: pointer;\n  padding: 4px;\n  line-height: 1;\n\n  &:hover {\n    color: #000000;\n  }\n"])));
var CodeInput = styled.input(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  width: 200px;\n  height: 52px;\n  background: #FFFFFF;\n  border: 1px solid #F1F1F1;\n  border-radius: 12px;\n  padding: 0 16px;\n  margin: 0 auto 16px;\n  font-family: 'Inter';\n  font-size: 15px;\n  text-align: center;\n  letter-spacing: 4px;\n\n  &:focus {\n    border-color: #8F4ACF;\n    outline: none;\n  }\n"], ["\n  width: 200px;\n  height: 52px;\n  background: #FFFFFF;\n  border: 1px solid #F1F1F1;\n  border-radius: 12px;\n  padding: 0 16px;\n  margin: 0 auto 16px;\n  font-family: 'Inter';\n  font-size: 15px;\n  text-align: center;\n  letter-spacing: 4px;\n\n  &:focus {\n    border-color: #8F4ACF;\n    outline: none;\n  }\n"])));
var SignIn = function (_a) {
    var setIsAuthenticated = _a.setIsAuthenticated;
    var navigate = useNavigate();
    var _b = useState(''), successMessage = _b[0], setSuccessMessage = _b[1];
    var _c = useState(''), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = useState(''), mfaToken = _d[0], setMfaToken = _d[1];
    var _e = useState(''), tempAuthToken = _e[0], setTempAuthToken = _e[1];
    var _f = useState(false), requiresMFA = _f[0], setRequiresMFA = _f[1];
    // Fetch CSRF token when component mounts
    useEffect(function () {
        var fetchCSRFToken = function () { return __awaiter(void 0, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.get('/api/csrf/')];
                    case 1:
                        _a.sent(); // We'll create this endpoint
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error fetching CSRF token:', error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        fetchCSRFToken();
    }, []);
    var handleSubmit = function (values_1, _a) { return __awaiter(void 0, [values_1, _a], void 0, function (values, _b) {
        var response, error_2;
        var _c, _d;
        var setSubmitting = _b.setSubmitting;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _e.trys.push([0, 2, 3, 4]);
                    return [4 /*yield*/, axios.post('/api/signin/', values)];
                case 1:
                    response = _e.sent();
                    if (response.data.requiresMFA) {
                        setRequiresMFA(true);
                        setTempAuthToken(response.data.tempToken);
                    }
                    else {
                        setIsAuthenticated(true);
                        navigate('/app');
                    }
                    return [3 /*break*/, 4];
                case 2:
                    error_2 = _e.sent();
                    setErrorMessage(((_d = (_c = error_2.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.error) || 'Sign in failed');
                    return [3 /*break*/, 4];
                case 3:
                    setSubmitting(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleMFAVerify = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_3;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!mfaToken || mfaToken.length !== 6) {
                        setErrorMessage('Please enter a valid 6-digit code');
                        return [2 /*return*/];
                    }
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.post('/api/mfa/verify/', {
                            token: mfaToken,
                            username: tempAuthToken
                        })];
                case 2:
                    response = _c.sent();
                    if (response.data.success) {
                        setIsAuthenticated(true);
                        navigate('/app');
                    }
                    else {
                        setErrorMessage('Invalid verification code');
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _c.sent();
                    console.error('MFA verification error:', error_3);
                    setErrorMessage(((_b = (_a = error_3.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) || 'Failed to verify MFA code');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    if (requiresMFA) {
        return (React.createElement(MFAModal, null,
            React.createElement(MFAContainer, null,
                React.createElement(CloseButton, { onClick: function () {
                        setRequiresMFA(false);
                        setMfaToken('');
                        setErrorMessage('');
                    } }, "\u00D7"),
                React.createElement("h2", null, "Two-Factor Authentication"),
                React.createElement("p", null, "Enter the verification code from your authenticator app"),
                React.createElement(CodeInput, { type: "text", value: mfaToken, onChange: function (e) {
                        var value = e.target.value.replace(/[^0-9]/g, '');
                        if (value.length <= 6) {
                            setMfaToken(value);
                        }
                    }, placeholder: "000000", maxLength: 6, autoFocus: true }),
                errorMessage && React.createElement(ErrorMessage, null, errorMessage),
                React.createElement(Button, { onClick: handleMFAVerify }, "Verify"))));
    }
    return (React.createElement(Container, null,
        React.createElement(Title, null, "Sign In"),
        successMessage && React.createElement(SuccessMessage, null, successMessage),
        errorMessage && React.createElement(ErrorMessage, null, errorMessage),
        React.createElement(Formik, { initialValues: { username: '', password: '' }, validationSchema: Yup.object({
                username: Yup.string().required('Username is required'),
                password: Yup.string().required('Password is required'),
            }), onSubmit: handleSubmit }, function (_a) {
            var errors = _a.errors, touched = _a.touched, isSubmitting = _a.isSubmitting;
            return (React.createElement(Form, null,
                React.createElement(FormGroup, null,
                    React.createElement(StyledField, { name: "username", type: "text", placeholder: "Username" }),
                    errors.username && touched.username && (React.createElement(ErrorMessage, null, errors.username))),
                React.createElement(FormGroup, null,
                    React.createElement(StyledField, { name: "password", type: "password", placeholder: "Password" }),
                    errors.password && touched.password && (React.createElement(ErrorMessage, null, errors.password)),
                    React.createElement(ForgotPasswordLink, { to: "/forgot-password" }, "Forgot Password?")),
                React.createElement(ButtonContainer, null,
                    React.createElement(Button, { type: "submit", disabled: isSubmitting }, "Sign In"))));
        })));
};
export default SignIn;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;

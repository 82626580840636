var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SettingsCard from './SettingsCard';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"], ["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"])));
var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"])));
var SectionTitle = styled.h2(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #000000;\n  margin: 40px 0 24px;\n  display: flex;\n  align-items: center;\n  \n  &:before {\n    content: '';\n    display: inline-block;\n    width: 4px;\n    height: 24px;\n    background: #8F4ACF;\n    margin-right: 12px;\n    border-radius: 2px;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #000000;\n  margin: 40px 0 24px;\n  display: flex;\n  align-items: center;\n  \n  &:before {\n    content: '';\n    display: inline-block;\n    width: 4px;\n    height: 24px;\n    background: #8F4ACF;\n    margin-right: 12px;\n    border-radius: 2px;\n  }\n"])));
var CardGrid = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n  gap: 24px;\n  padding: 12px;\n  width: 100%;\n  box-sizing: border-box;\n  \n  @media (max-width: 768px) {\n    grid-template-columns: 1fr;\n  }\n"], ["\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n  gap: 24px;\n  padding: 12px;\n  width: 100%;\n  box-sizing: border-box;\n  \n  @media (max-width: 768px) {\n    grid-template-columns: 1fr;\n  }\n"])));
var Section = styled.section(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 24px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n  width: 100%;\n  overflow: hidden;\n  box-sizing: border-box;\n  \n  &:hover {\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);\n  }\n"], ["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 24px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n  width: 100%;\n  overflow: hidden;\n  box-sizing: border-box;\n  \n  &:hover {\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);\n  }\n"])));
var CardWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  box-sizing: border-box;\n"], ["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  box-sizing: border-box;\n"])));
var UsageLimitationsCard = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 16px;\n  padding: 24px;\n  margin-bottom: 24px;\n"], ["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 16px;\n  padding: 24px;\n  margin-bottom: 24px;\n"])));
var UsageStats = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  gap: 24px;\n  margin-bottom: 24px;\n"], ["\n  display: flex;\n  gap: 24px;\n  margin-bottom: 24px;\n"])));
var StatBox = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  flex: 1;\n  background: #F8F5FF;\n  border-radius: 12px;\n  padding: 20px;\n"], ["\n  flex: 1;\n  background: #F8F5FF;\n  border-radius: 12px;\n  padding: 20px;\n"])));
var StatLabel = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n  margin-bottom: 8px;\n"], ["\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #6B6B6B;\n  margin-bottom: 8px;\n"])));
var StatValue = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #000000;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #000000;\n"])));
var ProgressBarContainer = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  width: 100%;\n  height: 8px;\n  background: #F0F0F0;\n  border-radius: 4px;\n  margin: 16px 0;\n  overflow: hidden;\n"], ["\n  width: 100%;\n  height: 8px;\n  background: #F0F0F0;\n  border-radius: 4px;\n  margin: 16px 0;\n  overflow: hidden;\n"])));
var ProgressBar = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  width: ", "%;\n  height: 100%;\n  background: ", ";\n  border-radius: 4px;\n  transition: width 0.3s ease;\n"], ["\n  width: ", "%;\n  height: 100%;\n  background: ", ";\n  border-radius: 4px;\n  transition: width 0.3s ease;\n"])), function (props) { return props.width; }, function (props) { return props.width > 90 ? '#EB564F' : '#8F4ACF'; });
var WarningMessage = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  background: #FFF5F5;\n  border: 1px solid #FED7D7;\n  border-radius: 8px;\n  padding: 16px;\n  color: #E53E3E;\n  font-family: 'Inter';\n  font-size: 14px;\n  margin-top: 16px;\n  display: ", ";\n"], ["\n  background: #FFF5F5;\n  border: 1px solid #FED7D7;\n  border-radius: 8px;\n  padding: 16px;\n  color: #E53E3E;\n  font-family: 'Inter';\n  font-size: 14px;\n  margin-top: 16px;\n  display: ", ";\n"])), function (props) { return props.hidden ? 'none' : 'block'; });
var MFASection = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  margin: 24px 0;\n  padding: 20px;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n"], ["\n  margin: 24px 0;\n  padding: 20px;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n"])));
var MFAButton = styled.button(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  padding: 12px 24px;\n  background: ", ";\n  color: ", ";\n  border: none;\n  border-radius: 8px;\n  cursor: ", ";\n  font-family: 'Inter';\n  \n  &:hover {\n    opacity: ", ";\n  }\n"], ["\n  padding: 12px 24px;\n  background: ", ";\n  color: ", ";\n  border: none;\n  border-radius: 8px;\n  cursor: ", ";\n  font-family: 'Inter';\n  \n  &:hover {\n    opacity: ", ";\n  }\n"])), function (props) { return props.enabled ? '#f5f5f5' : 'linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%)'; }, function (props) { return props.enabled ? '#666' : 'white'; }, function (props) { return props.enabled ? 'default' : 'pointer'; }, function (props) { return props.enabled ? 1 : 0.9; });
var Modal = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n"])));
var Settings = function () {
    var user = useAuth().user;
    var _a = useState(false), isStaff = _a[0], setIsStaff = _a[1];
    var _b = useState(false), isAdmin = _b[0], setIsAdmin = _b[1];
    var _c = useState(false), showMFASetup = _c[0], setShowMFASetup = _c[1];
    var _d = useState(false), mfaEnabled = _d[0], setMfaEnabled = _d[1];
    useEffect(function () {
        var checkAuthStatus = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, data, error_1;
            var _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _e.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, fetch('/api/check-auth/')];
                    case 1:
                        response = _e.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _e.sent();
                        setIsStaff((_b = (_a = data.user) === null || _a === void 0 ? void 0 : _a.is_staff) !== null && _b !== void 0 ? _b : false);
                        setIsAdmin((_d = (_c = data.user) === null || _c === void 0 ? void 0 : _c.is_superuser) !== null && _d !== void 0 ? _d : false);
                        console.log('Auth status:', data);
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _e.sent();
                        console.error('Error checking auth status:', error_1);
                        setIsStaff(false);
                        setIsAdmin(false);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        checkAuthStatus();
    }, []);
    useEffect(function () {
        // MFA durumunu kontrol et
        var checkMFAStatus = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.get('/api/mfa/status/')];
                    case 1:
                        response = _a.sent();
                        setMfaEnabled(response.data.mfaEnabled);
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.error('Failed to check MFA status:', error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        checkMFAStatus();
    }, []);
    var mockUsageData = {
        currentUsage: 8500,
        totalLimit: 10000,
        dailyUsage: 280,
        dailyLimit: 500,
        monthlyUsage: 8500,
        monthlyLimit: 10000
    };
    var usagePercentage = (mockUsageData.currentUsage / mockUsageData.totalLimit) * 100;
    var userSettings = [
        {
            title: "User Profile",
            description: "Update your personal information and preferences.",
            link: "/app/settings/profile",
            buttonText: "Edit Profile"
        },
        {
            title: "Account Security",
            description: "Manage your password and security settings.",
            link: "/app/settings/security",
            buttonText: "Change Password"
        },
        {
            title: "Two-Factor Authentication",
            description: "Set up 2FA using Google Authenticator for enhanced security.",
            link: "/app/settings/2fa",
            buttonText: "Manage 2FA"
        },
        {
            title: "Usages and Limitations",
            description: "View your usage and request more if needed.",
            link: "/app/settings/usage",
            buttonText: "View Usage"
        }
    ];
    var companySettings = [
        {
            title: "Service Account",
            description: "Upload and manage service account credentials.",
            link: "/app/settings/service-account",
            buttonText: "Upload Service Account"
        },
        {
            title: "Activity History",
            description: "View detailed usage activity history.",
            link: "/app/settings/activity",
            buttonText: "View History"
        },
        {
            title: "User Management",
            description: "Add, edit, or delete users.",
            link: "/app/settings/users",
            buttonText: "Manage Users"
        },
        {
            title: "Department Management",
            description: "Create and manage departments, assign permissions and users.",
            link: "/app/settings/departments",
            buttonText: "Manage Departments"
        },
        {
            title: "Company Quota Management",
            description: "Increase or decrease company quotas.",
            link: "/app/settings/quotas",
            buttonText: "Manage Quotas"
        },
        {
            title: "Database Connections",
            description: "Manage database connections.",
            link: "/app/settings/database-connections",
            buttonText: "Manage Database Connections"
        }
    ];
    var superAdminSettings = [
        {
            title: "Manage Companies",
            description: "Assign users to companies and manage their permissions.",
            link: "/app/settings/companies",
            buttonText: "Manage Companies"
        }
    ];
    var handleMFASetupComplete = function () {
        setShowMFASetup(false);
        setMfaEnabled(true);
    };
    return (React.createElement(Container, null,
        React.createElement(Title, null, "Settings"),
        React.createElement(Section, null,
            React.createElement(SectionTitle, null, "User Settings"),
            React.createElement(CardGrid, null, userSettings.map(function (setting, index) { return (React.createElement(CardWrapper, { key: index },
                React.createElement(SettingsCard, __assign({}, setting)))); }))),
        isStaff && (React.createElement(Section, null,
            React.createElement(SectionTitle, null, "Company Settings"),
            React.createElement(CardGrid, null, companySettings.map(function (setting, index) { return (React.createElement(CardWrapper, { key: index },
                React.createElement(SettingsCard, __assign({}, setting)))); })))),
        isStaff && isAdmin && (React.createElement(Section, null,
            React.createElement(SectionTitle, null, "Superadmin Settings"),
            React.createElement(CardGrid, null, superAdminSettings.map(function (setting, index) { return (React.createElement(CardWrapper, { key: index },
                React.createElement(SettingsCard, __assign({}, setting)))); }))))));
};
export default Settings;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17;

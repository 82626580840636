var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
var AuthContext = createContext({
    user: null,
    loading: true,
    setIsAuthenticated: function () { }
});
export var AuthProvider = function (_a) {
    var children = _a.children;
    var _b = useState(null), user = _b[0], setUser = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(false), initialized = _d[0], setInitialized = _d[1];
    var setIsAuthenticated = function (value) {
        if (!value) {
            setUser(null);
        }
    };
    var checkAuth = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, userData, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    console.log('Checking auth...', new Date().toISOString());
                    return [4 /*yield*/, axios.get('/api/check-auth/', {
                            withCredentials: true
                        })];
                case 1:
                    response = _a.sent();
                    console.log('Auth response:', response.data, new Date().toISOString());
                    if (response.data.authenticated && response.data.user) {
                        userData = {
                            id: response.data.user.id,
                            username: response.data.user.name,
                            name: response.data.user.name,
                            email: response.data.user.email,
                            isAdmin: response.data.user.is_admin,
                            isSuperAdmin: response.data.user.is_superuser,
                            firstName: response.data.user.first_name,
                            lastName: response.data.user.last_name,
                            phone: response.data.user.phone,
                            company: response.data.user.company,
                            department: response.data.user.department,
                            position: response.data.user.position,
                            avatarUrl: response.data.user.avatar_url
                        };
                        console.log('Setting user data:', userData);
                        setUser(userData);
                    }
                    else {
                        setUser(null);
                    }
                    return [3 /*break*/, 4];
                case 2:
                    error_1 = _a.sent();
                    console.error('Auth check error:', error_1);
                    setUser(null);
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    setInitialized(true);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        checkAuth();
    }, []);
    // Don't render children until we've initialized
    if (!initialized) {
        return React.createElement("div", null, "Initializing...");
    }
    return (React.createElement(AuthContext.Provider, { value: { user: user, loading: loading, setIsAuthenticated: setIsAuthenticated } }, children));
};
export var useAuth = function () { return useContext(AuthContext); };

import React, { useState, useEffect, useRef } from "react";
var LazyLoadGif = function (_a) {
    var gifUrl = _a.gifUrl, caption = _a.caption;
    var _b = useState(false), isVisible = _b[0], setIsVisible = _b[1];
    var ref = useRef(null);
    useEffect(function () {
        var observer = new IntersectionObserver(function (_a) {
            var entry = _a[0];
            if (entry.isIntersecting) {
                setIsVisible(true);
                observer.disconnect();
            }
        }, { threshold: 0.1 });
        if (ref.current) {
            observer.observe(ref.current);
        }
        return function () {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);
    return (React.createElement("div", { ref: ref, style: { minHeight: "200px" } },
        isVisible ? (React.createElement("img", { src: gifUrl, alt: caption, style: { width: "100%", height: "auto" } })) : (React.createElement("div", { style: { width: "100%", height: "200px", background: "#f0f0f0" } })),
        React.createElement("p", null, caption)));
};
export default LazyLoadGif;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import LogoDark from '../../assets/LOGO-dark-vertical.png';
import linkedin_logo from '../../assets/linkedin_logo.png';
import github_icon from '../../assets/github_icon.png';
import mail_icon from '../../assets/mail_icon.png';
import { Link } from 'react-router-dom';
var FooterContainer = styled.footer(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  background: #000000;\n  padding: 80px 0 40px;\n  margin-top: 80px;\n"], ["\n  position: relative;\n  width: 100%;\n  background: #000000;\n  padding: 80px 0 40px;\n  margin-top: 80px;\n"])));
var FooterContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  max-width: 1200px;\n  margin: 0 auto;\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  gap: 40px;\n  padding: 0 20px;\n\n  @media (max-width: 1200px) {\n    grid-template-columns: repeat(2, 1fr);\n  }\n\n  @media (max-width: 768px) {\n    grid-template-columns: 1fr;\n  }\n"], ["\n  position: relative;\n  width: 100%;\n  max-width: 1200px;\n  margin: 0 auto;\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  gap: 40px;\n  padding: 0 20px;\n\n  @media (max-width: 1200px) {\n    grid-template-columns: repeat(2, 1fr);\n  }\n\n  @media (max-width: 768px) {\n    grid-template-columns: 1fr;\n  }\n"])));
var FooterLogo = styled(Link)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 165.76px;\n  height: auto;\n  margin-bottom: 20px;\n  display: block;\n  text-decoration: none;\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n"], ["\n  width: 165.76px;\n  height: auto;\n  margin-bottom: 20px;\n  display: block;\n  text-decoration: none;\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n"])));
var LinkedInLogo = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 24px;\n  height: 24px;\n  filter: brightness(0) saturate(100%) invert(56%) sepia(83%) saturate(1960%) hue-rotate(233deg) brightness(97%) contrast(91%);\n  transition: filter 0.2s ease;\n\n  &:hover {\n    filter: brightness(0) saturate(100%) invert(66%) sepia(83%) saturate(1960%) hue-rotate(233deg) brightness(97%) contrast(91%);\n  }\n"], ["\n  width: 24px;\n  height: 24px;\n  filter: brightness(0) saturate(100%) invert(56%) sepia(83%) saturate(1960%) hue-rotate(233deg) brightness(97%) contrast(91%);\n  transition: filter 0.2s ease;\n\n  &:hover {\n    filter: brightness(0) saturate(100%) invert(66%) sepia(83%) saturate(1960%) hue-rotate(233deg) brightness(97%) contrast(91%);\n  }\n"])));
var FooterColumn = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var FooterHeading = styled.h3(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 20px;\n  line-height: 24px;\n  color: #FFFFFF;\n  margin-bottom: 36px;\n"], ["\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 20px;\n  line-height: 24px;\n  color: #FFFFFF;\n  margin-bottom: 36px;\n"])));
var FooterLinks = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 17px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 17px;\n"])));
var FooterLink = styled.a(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 17px;\n  color: #D3D3D3;\n  text-decoration: none;\n  transition: color 0.2s ease;\n\n  &:hover {\n    color: #FFFFFF;\n  }\n"], ["\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 17px;\n  color: #D3D3D3;\n  text-decoration: none;\n  transition: color 0.2s ease;\n\n  &:hover {\n    color: #FFFFFF;\n  }\n"])));
var SocialLinks = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  gap: 16px;\n"], ["\n  display: flex;\n  gap: 16px;\n"])));
var SocialIcon = styled(motion.a)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  background: rgba(143, 74, 207, 0.1);\n  transition: all 0.2s ease;\n\n  img {\n    width: 24px;\n    height: 24px;\n    filter: brightness(0) saturate(100%) invert(56%) sepia(83%) saturate(1960%) hue-rotate(233deg) brightness(97%) contrast(91%);\n    transition: filter 0.2s ease;\n\n    &[alt=\"Email\"] {\n      width: 20px;\n      height: 20px;\n      opacity: 0.9;\n    }\n  }\n\n  &:hover {\n    background: rgba(143, 74, 207, 0.2);\n    transform: translateY(-2px);\n\n    img {\n      filter: brightness(0) saturate(100%) invert(66%) sepia(83%) saturate(1960%) hue-rotate(233deg) brightness(97%) contrast(91%);\n      \n      &[alt=\"Email\"] {\n        opacity: 1;\n      }\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  background: rgba(143, 74, 207, 0.1);\n  transition: all 0.2s ease;\n\n  img {\n    width: 24px;\n    height: 24px;\n    filter: brightness(0) saturate(100%) invert(56%) sepia(83%) saturate(1960%) hue-rotate(233deg) brightness(97%) contrast(91%);\n    transition: filter 0.2s ease;\n\n    &[alt=\"Email\"] {\n      width: 20px;\n      height: 20px;\n      opacity: 0.9;\n    }\n  }\n\n  &:hover {\n    background: rgba(143, 74, 207, 0.2);\n    transform: translateY(-2px);\n\n    img {\n      filter: brightness(0) saturate(100%) invert(66%) sepia(83%) saturate(1960%) hue-rotate(233deg) brightness(97%) contrast(91%);\n      \n      &[alt=\"Email\"] {\n        opacity: 1;\n      }\n    }\n  }\n"])));
var Divider = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 1200px;\n  height: 0px;\n  margin: 40px auto;\n  border: 1px solid #292929;\n  padding: 0 20px;\n"], ["\n  width: 100%;\n  max-width: 1200px;\n  height: 0px;\n  margin: 40px auto;\n  border: 1px solid #292929;\n  padding: 0 20px;\n"])));
var FooterBottom = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  max-width: 1200px;\n  margin: 0 auto;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 20px;\n\n  @media (max-width: 768px) {\n    flex-direction: column;\n    gap: 20px;\n    text-align: center;\n  }\n"], ["\n  position: relative;\n  width: 100%;\n  max-width: 1200px;\n  margin: 0 auto;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 20px;\n\n  @media (max-width: 768px) {\n    flex-direction: column;\n    gap: 20px;\n    text-align: center;\n  }\n"])));
var Copyright = styled.p(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 300;\n  font-size: 12px;\n  line-height: 110%;\n  color: #A1A1A1;\n"], ["\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 300;\n  font-size: 12px;\n  line-height: 110%;\n  color: #A1A1A1;\n"])));
var FooterNav = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  gap: 24px;\n"], ["\n  display: flex;\n  gap: 24px;\n"])));
var FooterNavLink = styled.a(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 300;\n  font-size: 12px;\n  line-height: 110%;\n  color: #A1A1A1;\n  text-decoration: none;\n\n  &:hover {\n    color: #FFFFFF;\n  }\n"], ["\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 300;\n  font-size: 12px;\n  line-height: 110%;\n  color: #A1A1A1;\n  text-decoration: none;\n\n  &:hover {\n    color: #FFFFFF;\n  }\n"])));
export default function FooterMain() {
    return (React.createElement(FooterContainer, null,
        React.createElement(FooterContent, null,
            React.createElement(FooterColumn, null,
                React.createElement(FooterLogo, { to: "/" },
                    React.createElement("img", { src: LogoDark, alt: "Cognitio Intelligence" }))),
            React.createElement(FooterColumn, null,
                React.createElement(FooterHeading, null, "Platform"),
                React.createElement(FooterLinks, null,
                    React.createElement(FooterLink, { href: "/features" }, "Features"),
                    React.createElement(FooterLink, { href: "/pricing" }, "Pricing"),
                    React.createElement(FooterLink, { href: "/support" }, "Support"))),
            React.createElement(FooterColumn, null,
                React.createElement(FooterHeading, null, "Company"),
                React.createElement(FooterLinks, null,
                    React.createElement(FooterLink, { href: "/about" }, "About"),
                    React.createElement(FooterLink, { href: "/contact" }, "Contact"))),
            React.createElement(FooterColumn, null,
                React.createElement(FooterHeading, null, "Connect"),
                React.createElement(SocialLinks, null,
                    React.createElement(SocialIcon, { href: "https://www.linkedin.com/company/cognitio-intelligence", target: "_blank", rel: "noopener noreferrer", whileHover: { scale: 1.05 }, whileTap: { scale: 0.95 } },
                        React.createElement("img", { src: linkedin_logo, alt: "LinkedIn" })),
                    React.createElement(SocialIcon, { href: "https://github.com/Cognitio-Intelligence", target: "_blank", rel: "noopener noreferrer", whileHover: { scale: 1.05 }, whileTap: { scale: 0.95 } },
                        React.createElement("img", { src: github_icon, alt: "GitHub" })),
                    React.createElement(SocialIcon, { href: "mailto:contact@cognitioi.com", whileHover: { scale: 1.05 }, whileTap: { scale: 0.95 } },
                        React.createElement("img", { src: mail_icon, alt: "Email" }))))),
        React.createElement(Divider, null),
        React.createElement(FooterBottom, null,
            React.createElement(Copyright, null, "\u00A9 2025 Cognitio Intelligence. All rights reserved."),
            React.createElement(FooterNav, null,
                React.createElement(FooterNavLink, { as: Link, to: "/privacy-policy" }, "Privacy Policy"),
                React.createElement(FooterNavLink, { as: Link, to: "/terms" }, "Terms"),
                React.createElement(FooterNavLink, { as: Link, to: "/cookie-policy" }, "Cookies")))));
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import FooterMain from './Layout/footer_main';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { FaEnvelope, FaCalendar } from 'react-icons/fa';
var PageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-height: 100vh;\n  background: #FFFFFF;\n  padding-top: 60px;\n  position: relative;\n  overflow: hidden;\n"], ["\n  min-height: 100vh;\n  background: #FFFFFF;\n  padding-top: 60px;\n  position: relative;\n  overflow: hidden;\n"])));
var MainContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 0 20px;\n  text-align: center;\n"], ["\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 0 20px;\n  text-align: center;\n"])));
var Title = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 58px;\n  font-weight: 700;\n  line-height: 106%;\n  margin-bottom: 24px;\n  color: #000000;\n\n  span {\n    background: linear-gradient(90deg, #A65CE8 0%, #7094F4 100%);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n  }\n"], ["\n  font-family: 'Inter';\n  font-size: 58px;\n  font-weight: 700;\n  line-height: 106%;\n  margin-bottom: 24px;\n  color: #000000;\n\n  span {\n    background: linear-gradient(90deg, #A65CE8 0%, #7094F4 100%);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n  }\n"])));
var Subtitle = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 18px;\n  line-height: 22px;\n  text-align: center;\n  color: #000000;\n  max-width: 761px;\n  margin: 0 auto 60px;\n"], ["\n  font-family: 'Inter';\n  font-size: 18px;\n  line-height: 22px;\n  text-align: center;\n  color: #000000;\n  max-width: 761px;\n  margin: 0 auto 60px;\n"])));
var ContentGrid = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 1fr 1.5fr;\n  gap: 40px;\n  max-width: 1200px;\n  margin: 0 auto;\n"], ["\n  display: grid;\n  grid-template-columns: 1fr 1.5fr;\n  gap: 40px;\n  max-width: 1200px;\n  margin: 0 auto;\n"])));
var ContactInfo = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background: #FFFFFF;\n  box-shadow: 16px 32px 74px rgba(28, 59, 168, 0.1);\n  border-radius: 30px;\n  padding: 60px;\n  height: fit-content;\n"], ["\n  background: #FFFFFF;\n  box-shadow: 16px 32px 74px rgba(28, 59, 168, 0.1);\n  border-radius: 30px;\n  padding: 60px;\n  height: fit-content;\n"])));
var InfoTitle = styled.h2(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 32px;\n  margin-bottom: 40px;\n  color: #000000;\n"], ["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 32px;\n  margin-bottom: 40px;\n  color: #000000;\n"])));
var InfoItem = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin-bottom: 24px;\n  \n  svg {\n    color: #A65CE8;\n    font-size: 24px;\n    margin-right: 16px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  margin-bottom: 24px;\n  \n  svg {\n    color: #A65CE8;\n    font-size: 24px;\n    margin-right: 16px;\n  }\n"])));
var InfoText = styled.p(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 16px;\n  color: #666666;\n"], ["\n  font-family: 'Inter';\n  font-size: 16px;\n  color: #666666;\n"])));
var FormContainer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background: #FFFFFF;\n  box-shadow: 16px 32px 74px rgba(28, 59, 168, 0.1);\n  border-radius: 30px;\n  padding: 60px;\n  position: relative;\n\n  &::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    height: 4px;\n    background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n    border-radius: 30px 30px 0 0;\n  }\n"], ["\n  background: #FFFFFF;\n  box-shadow: 16px 32px 74px rgba(28, 59, 168, 0.1);\n  border-radius: 30px;\n  padding: 60px;\n  position: relative;\n\n  &::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    height: 4px;\n    background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n    border-radius: 30px 30px 0 0;\n  }\n"])));
var FormTitle = styled.h2(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 32px;\n  margin-bottom: 40px;\n  color: #000000;\n"], ["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 32px;\n  margin-bottom: 40px;\n  color: #000000;\n"])));
var InputGroup = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin-bottom: 24px;\n"], ["\n  margin-bottom: 24px;\n"])));
var Input = styled.input(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  width: 100%;\n  padding: 16px;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  font-family: 'Inter';\n  font-size: 16px;\n  transition: all 0.3s ease;\n\n  &:focus {\n    outline: none;\n    border-color: #A65CE8;\n    box-shadow: 0 0 0 4px rgba(166, 92, 232, 0.1);\n  }\n\n  &::placeholder {\n    color: #666666;\n  }\n"], ["\n  width: 100%;\n  padding: 16px;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  font-family: 'Inter';\n  font-size: 16px;\n  transition: all 0.3s ease;\n\n  &:focus {\n    outline: none;\n    border-color: #A65CE8;\n    box-shadow: 0 0 0 4px rgba(166, 92, 232, 0.1);\n  }\n\n  &::placeholder {\n    color: #666666;\n  }\n"])));
var TextArea = styled.textarea(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  width: 100%;\n  padding: 16px;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  font-family: 'Inter';\n  font-size: 16px;\n  min-height: 150px;\n  resize: vertical;\n  transition: all 0.3s ease;\n\n  &:focus {\n    outline: none;\n    border-color: #A65CE8;\n    box-shadow: 0 0 0 4px rgba(166, 92, 232, 0.1);\n  }\n\n  &::placeholder {\n    color: #666666;\n  }\n"], ["\n  width: 100%;\n  padding: 16px;\n  border: 1px solid #ECEAFF;\n  border-radius: 12px;\n  font-family: 'Inter';\n  font-size: 16px;\n  min-height: 150px;\n  resize: vertical;\n  transition: all 0.3s ease;\n\n  &:focus {\n    outline: none;\n    border-color: #A65CE8;\n    box-shadow: 0 0 0 4px rgba(166, 92, 232, 0.1);\n  }\n\n  &::placeholder {\n    color: #666666;\n  }\n"])));
var SubmitButton = styled(motion.button)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n  border: none;\n  border-radius: 100px;\n  padding: 16px 48px;\n  color: white;\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 16px;\n  cursor: pointer;\n  width: 100%;\n  max-width: 200px;\n  transition: transform 0.3s ease;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n\n  &:hover {\n    transform: translateY(-2px);\n  }\n\n  &:disabled {\n    opacity: 0.7;\n    cursor: not-allowed;\n  }\n"], ["\n  background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n  border: none;\n  border-radius: 100px;\n  padding: 16px 48px;\n  color: white;\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 16px;\n  cursor: pointer;\n  width: 100%;\n  max-width: 200px;\n  transition: transform 0.3s ease;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n\n  &:hover {\n    transform: translateY(-2px);\n  }\n\n  &:disabled {\n    opacity: 0.7;\n    cursor: not-allowed;\n  }\n"])));
var BackgroundDecoration = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  position: absolute;\n  width: 600px;\n  height: 600px;\n  border-radius: 50%;\n  background: radial-gradient(circle, rgba(166, 92, 232, 0.1) 0%, rgba(166, 92, 232, 0) 70%);\n  z-index: -1;\n  \n  &.top-right {\n    top: -200px;\n    right: -200px;\n  }\n  \n  &.bottom-left {\n    bottom: -200px;\n    left: -200px;\n    background: radial-gradient(circle, rgba(112, 148, 244, 0.1) 0%, rgba(112, 148, 244, 0) 70%);\n  }\n"], ["\n  position: absolute;\n  width: 600px;\n  height: 600px;\n  border-radius: 50%;\n  background: radial-gradient(circle, rgba(166, 92, 232, 0.1) 0%, rgba(166, 92, 232, 0) 70%);\n  z-index: -1;\n  \n  &.top-right {\n    top: -200px;\n    right: -200px;\n  }\n  \n  &.bottom-left {\n    bottom: -200px;\n    left: -200px;\n    background: radial-gradient(circle, rgba(112, 148, 244, 0.1) 0%, rgba(112, 148, 244, 0) 70%);\n  }\n"])));
var LoadingSpinner = styled(motion.div)(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  width: 20px;\n  height: 20px;\n  border: 2px solid #FFFFFF;\n  border-top: 2px solid transparent;\n  border-radius: 50%;\n  margin-left: 8px;\n"], ["\n  width: 20px;\n  height: 20px;\n  border: 2px solid #FFFFFF;\n  border-top: 2px solid transparent;\n  border-radius: 50%;\n  margin-left: 8px;\n"])));
var PricingPage = function () {
    var _a = useState({
        name: '',
        email: '',
        company: '',
        message: ''
    }), formData = _a[0], setFormData = _a[1];
    var _b = useState(false), isSubmitting = _b[0], setIsSubmitting = _b[1];
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    e.preventDefault();
                    if (!formData.name || !formData.email || !formData.company || !formData.message) {
                        toast.error('Please fill in all fields');
                        return [2 /*return*/];
                    }
                    setIsSubmitting(true);
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, axios.post('/api/request-demo/', formData)];
                case 2:
                    response = _d.sent();
                    if (response.data.status === 'success') {
                        toast.success(response.data.message || 'Demo request sent successfully!');
                        setFormData({
                            name: '',
                            email: '',
                            company: '',
                            message: ''
                        });
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _d.sent();
                    console.error('Form submission error:', ((_a = error_1.response) === null || _a === void 0 ? void 0 : _a.data) || error_1);
                    toast.error(((_c = (_b = error_1.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message) || 'An error occurred while sending your request.');
                    return [3 /*break*/, 5];
                case 4:
                    setIsSubmitting(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(PageContainer, null,
        React.createElement(BackgroundDecoration, { className: "top-right" }),
        React.createElement(BackgroundDecoration, { className: "bottom-left" }),
        React.createElement(MainContent, null,
            React.createElement(Title, null,
                "Book a ",
                React.createElement("span", null, "Demo")),
            React.createElement(Subtitle, null, "Experience the power of our platform firsthand. Schedule a personalized demo with our team."),
            React.createElement(ContentGrid, null,
                React.createElement(ContactInfo, null,
                    React.createElement(InfoTitle, null, "Contact Information"),
                    React.createElement(InfoItem, null,
                        React.createElement(FaEnvelope, null),
                        React.createElement(InfoText, null, "contact@cognitioi.com")),
                    React.createElement(InfoItem, null,
                        React.createElement(FaCalendar, null),
                        React.createElement(InfoText, null, "Available Monday to Friday, 9:00 AM - 6:00 PM GMT"))),
                React.createElement(FormContainer, null,
                    React.createElement(FormTitle, null, "Schedule Your Demo"),
                    React.createElement("form", { onSubmit: handleSubmit },
                        React.createElement(InputGroup, null,
                            React.createElement(Input, { type: "text", placeholder: "Name", value: formData.name, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { name: e.target.value })); }, disabled: isSubmitting })),
                        React.createElement(InputGroup, null,
                            React.createElement(Input, { type: "text", placeholder: "Company", value: formData.company, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { company: e.target.value })); }, disabled: isSubmitting })),
                        React.createElement(InputGroup, null,
                            React.createElement(Input, { type: "email", placeholder: "Email", value: formData.email, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { email: e.target.value })); }, disabled: isSubmitting })),
                        React.createElement(InputGroup, null,
                            React.createElement(TextArea, { placeholder: "Tell us about your needs and preferred demo time", value: formData.message, onChange: function (e) { return setFormData(__assign(__assign({}, formData), { message: e.target.value })); }, disabled: isSubmitting })),
                        React.createElement(SubmitButton, { whileHover: { scale: 1.05 }, whileTap: { scale: 0.95 }, type: "submit", disabled: isSubmitting }, isSubmitting ? (React.createElement(React.Fragment, null, "Sending...")) : ('Book Demo')))))),
        React.createElement(FooterMain, null)));
};
export default PricingPage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17;

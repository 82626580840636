var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import styled from 'styled-components';
var OnboardingContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: transparent;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 1002;\n  pointer-events: auto;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: transparent;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 1002;\n  pointer-events: auto;\n"])));
var OnboardingContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: #FFFFFF;\n  padding: 30px;\n  border-radius: 20px;\n  max-width: 500px;\n  width: 100%;\n  box-shadow: \n    0 4px 20px rgba(0, 0, 0, 0.1),\n    0 0 0 1px rgba(143, 74, 207, 0.1);\n  position: relative;\n  z-index: 1004;\n"], ["\n  background: #FFFFFF;\n  padding: 30px;\n  border-radius: 20px;\n  max-width: 500px;\n  width: 100%;\n  box-shadow: \n    0 4px 20px rgba(0, 0, 0, 0.1),\n    0 0 0 1px rgba(143, 74, 207, 0.1);\n  position: relative;\n  z-index: 1004;\n"])));
var StepContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 20px 0;\n"], ["\n  margin: 20px 0;\n"])));
var ButtonContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  margin-top: 20px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  margin-top: 20px;\n"])));
var Button = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 10px 20px;\n  border-radius: 8px;\n  border: none;\n  cursor: pointer;\n  font-weight: 500;\n  background: ", ";\n  color: ", ";\n\n  &:hover {\n    opacity: 0.9;\n  }\n"], ["\n  padding: 10px 20px;\n  border-radius: 8px;\n  border: none;\n  cursor: pointer;\n  font-weight: 500;\n  background: ", ";\n  color: ", ";\n\n  &:hover {\n    opacity: 0.9;\n  }\n"])), function (props) { return props.primary ? 'linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%)' : '#f5f5f5'; }, function (props) { return props.primary ? 'white' : 'black'; });
var StyledTitle = styled.h2(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n  font-size: 24px;\n  margin-bottom: 16px;\n  font-weight: 600;\n"], ["\n  background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n  font-size: 24px;\n  margin-bottom: 16px;\n  font-weight: 600;\n"])));
var StepIndicator = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  gap: 8px;\n  margin-top: 20px;\n  justify-content: center;\n"], ["\n  display: flex;\n  gap: 8px;\n  margin-top: 20px;\n  justify-content: center;\n"])));
var StepDot = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 8px;\n  height: 8px;\n  border-radius: 50%;\n  background: ", ";\n  transition: all 0.3s ease;\n"], ["\n  width: 8px;\n  height: 8px;\n  border-radius: 50%;\n  background: ", ";\n  transition: all 0.3s ease;\n"])), function (props) { return props.active ?
    'linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%)' :
    'rgba(143, 74, 207, 0.2)'; });
var Highlight = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: fixed;\n  top: ", "px;\n  left: ", "px;\n  width: ", "px;\n  height: ", "px;\n  border-radius: 12px;\n  pointer-events: none;\n  z-index: 1003;\n  animation: ", " 1s ease-out;\n  transition: all 0.3s ease;\n\n  &::before {\n    content: '';\n    position: absolute;\n    inset: -2px;\n    border-radius: 14px;\n    border: 2px solid rgba(143, 74, 207, 0.6);\n    background: rgba(255, 255, 255, 0.1);\n    box-shadow: 0 0 15px rgba(143, 74, 207, 0.3);\n    z-index: 1003;\n  }\n\n  &::after {\n    content: '';\n    position: absolute;\n    inset: 0;\n    border-radius: 12px;\n    background: transparent;\n    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);\n    pointer-events: none;\n    z-index: 1002;\n  }\n"], ["\n  position: fixed;\n  top: ", "px;\n  left: ", "px;\n  width: ", "px;\n  height: ", "px;\n  border-radius: 12px;\n  pointer-events: none;\n  z-index: 1003;\n  animation: ", " 1s ease-out;\n  transition: all 0.3s ease;\n\n  &::before {\n    content: '';\n    position: absolute;\n    inset: -2px;\n    border-radius: 14px;\n    border: 2px solid rgba(143, 74, 207, 0.6);\n    background: rgba(255, 255, 255, 0.1);\n    box-shadow: 0 0 15px rgba(143, 74, 207, 0.3);\n    z-index: 1003;\n  }\n\n  &::after {\n    content: '';\n    position: absolute;\n    inset: 0;\n    border-radius: 12px;\n    background: transparent;\n    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);\n    pointer-events: none;\n    z-index: 1002;\n  }\n"])), function (props) { return props.position.top; }, function (props) { return props.position.left; }, function (props) { return props.position.width; }, function (props) { return props.position.height; }, function (props) { return props.animation; });
var ContentContainer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  position: relative;\n  z-index: 1002;\n"], ["\n  position: relative;\n  z-index: 1002;\n"])));
var CloseButton = styled.button(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  position: absolute;\n  top: 20px;\n  right: 20px;\n  width: 32px;\n  height: 32px;\n  border: none;\n  background: transparent;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n  transition: all 0.2s ease;\n\n  &:hover {\n    background: rgba(143, 74, 207, 0.1);\n  }\n\n  &::before,\n  &::after {\n    content: '';\n    position: absolute;\n    width: 16px;\n    height: 2px;\n    background: #8F4ACF;\n    border-radius: 1px;\n  }\n\n  &::before {\n    transform: rotate(45deg);\n  }\n\n  &::after {\n    transform: rotate(-45deg);\n  }\n"], ["\n  position: absolute;\n  top: 20px;\n  right: 20px;\n  width: 32px;\n  height: 32px;\n  border: none;\n  background: transparent;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n  transition: all 0.2s ease;\n\n  &:hover {\n    background: rgba(143, 74, 207, 0.1);\n  }\n\n  &::before,\n  &::after {\n    content: '';\n    position: absolute;\n    width: 16px;\n    height: 2px;\n    background: #8F4ACF;\n    border-radius: 1px;\n  }\n\n  &::before {\n    transform: rotate(45deg);\n  }\n\n  &::after {\n    transform: rotate(-45deg);\n  }\n"])));
var onboardingSteps = [
    {
        title: "Welcome to Cognitio Intelligence!",
        content: "Let's explore your powerful AI analytics dashboard together.",
        highlight: null,
        targetSelector: "[data-tour='home']",
        animation: "fadeIn"
    },
    {
        title: "Your Analytics Dashboard",
        content: "Get a bird's eye view of your data insights, trends, and key metrics. Customize your folders for what matters most to you.",
        highlight: null,
        targetSelector: "[data-tour='dashboard']",
        animation: "slideRight"
    },
    {
        title: "Natural Language Analysis",
        content: "Ask questions in plain English and get instant insights. Our AI understands your business context.",
        highlight: null,
        targetSelector: "[data-tour='analysis']",
        animation: "slideRight"
    },
    {
        title: "Data Explorer",
        content: "Explore your data dictionary and understand your data structure. Keep your data organized and documented.",
        highlight: null,
        targetSelector: "[data-tour='data-explorer']",
        animation: "slideRight"
    },
    {
        title: "Data Glossary",
        content: "Create a data glossary to keep your data organized and documented. Go into the data dictionary to see the full list of columns and their descriptions.",
        highlight: null,
        targetSelector: "[data-tour='glossary']",
        animation: "slideUp"
    },
    {
        title: "Question History",
        content: "Access your previous analyses and insights. Learn from past queries and build on your discoveries.",
        highlight: null,
        targetSelector: "[data-tour='history']",
        animation: "slideLeft"
    },
    {
        title: "Settings",
        content: "Manage your account settings, connect your data sources, and customize your experience to match your workflow.",
        highlight: null,
        targetSelector: "[data-tour='settings']",
        animation: "slideLeft"
    }
];
var getCookie = function (name) {
    var cookieValue = '';
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
};
var Onboarding = function (_a) {
    var onClose = _a.onClose, setSidebarOpen = _a.setSidebarOpen;
    var _b = useState(0), currentStep = _b[0], setCurrentStep = _b[1];
    var _c = useState(null), highlightPosition = _c[0], setHighlightPosition = _c[1];
    React.useEffect(function () {
        setSidebarOpen(true);
    }, [setSidebarOpen]);
    var updateHighlight = function (selector) {
        if (!selector) {
            setHighlightPosition(null);
            return;
        }
        var element = document.querySelector(selector);
        if (element) {
            var rect = element.getBoundingClientRect();
            setHighlightPosition({
                top: rect.top,
                left: rect.left,
                width: rect.width,
                height: rect.height
            });
        }
    };
    React.useEffect(function () {
        var step = onboardingSteps[currentStep];
        if (step.targetSelector) {
            updateHighlight(step.targetSelector);
        }
        else {
            setHighlightPosition(null);
        }
    }, [currentStep]);
    var handleFinish = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch('/api/user/mark-first-login/', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': getCookie('csrftoken')
                            }
                        })];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    console.log('Mark first login response:', data);
                    if (data.success) {
                        onClose();
                    }
                    else {
                        console.error('Failed to mark first login as complete');
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error marking first login:', error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var step = onboardingSteps[currentStep];
    return (React.createElement(OnboardingContainer, null,
        highlightPosition && (React.createElement(Highlight, { position: highlightPosition, animation: step.animation })),
        React.createElement(OnboardingContent, null,
            React.createElement(CloseButton, { onClick: onClose, "aria-label": "Close onboarding" }),
            React.createElement(ContentContainer, null,
                React.createElement(StyledTitle, null, step.title),
                React.createElement(StepContent, null,
                    React.createElement("p", null, step.content)),
                React.createElement(ButtonContainer, null,
                    currentStep > 0 && (React.createElement(Button, { onClick: function () { return setCurrentStep(function (prev) { return prev - 1; }); } }, "Previous")),
                    currentStep === onboardingSteps.length - 1 ? (React.createElement(Button, { onClick: handleFinish }, "Finish Tour")) : (React.createElement(Button, { onClick: function () { return setCurrentStep(function (prev) { return prev + 1; }); } }, "Next")))))));
};
export default Onboarding;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { BarChart, Bar, LineChart, Line, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import RGL, { WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
var ReactGridLayout = WidthProvider(RGL);
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 20px;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n"], ["\n  padding: 20px;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n"])));
var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 32px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 32px;\n"])));
var Title = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 24px;\n  color: #000000;\n"], ["\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 24px;\n  color: #000000;\n"])));
var ButtonGroup = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  gap: 16px;\n"], ["\n  display: flex;\n  gap: 16px;\n"])));
var ExportButton = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 12px 20px;\n  height: 44px;\n  background: ", ";\n  border: 1px solid ", ";\n  border-radius: 12px;\n  color: ", ";\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n\n  &:hover {\n    transform: translateY(-1px);\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n    background: ", ";\n  }\n\n  &:disabled {\n    opacity: 0.5;\n    cursor: not-allowed;\n    transform: none;\n    box-shadow: none;\n  }\n\n  svg {\n    width: 16px;\n    height: 16px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 12px 20px;\n  height: 44px;\n  background: ", ";\n  border: 1px solid ", ";\n  border-radius: 12px;\n  color: ", ";\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  transition: all 0.2s ease;\n\n  &:hover {\n    transform: translateY(-1px);\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n    background: ", ";\n  }\n\n  &:disabled {\n    opacity: 0.5;\n    cursor: not-allowed;\n    transform: none;\n    box-shadow: none;\n  }\n\n  svg {\n    width: 16px;\n    height: 16px;\n  }\n"])), function (_a) {
    var variant = _a.variant;
    switch (variant) {
        case 'sheets': return '#EDFBF2';
        case 'slides': return '#FEF8E0';
        case 'manage': return '#F8F5FF';
        case 'refresh': return '#E5F6FF';
        default: return '#EDFBF2';
    }
}, function (_a) {
    var variant = _a.variant;
    switch (variant) {
        case 'sheets': return '#7AD28C';
        case 'slides': return '#F6C763';
        case 'manage': return '#8F4ACF';
        case 'refresh': return '#3B82F6';
        default: return '#7AD28C';
    }
}, function (_a) {
    var variant = _a.variant;
    switch (variant) {
        case 'sheets': return '#7AD28C';
        case 'slides': return '#F6C763';
        case 'manage': return '#8F4ACF';
        case 'refresh': return '#3B82F6';
        default: return '#7AD28C';
    }
}, function (_a) {
    var variant = _a.variant;
    switch (variant) {
        case 'sheets': return '#E2F5E9';
        case 'slides': return '#FEF3D4';
        case 'manage': return '#F0E7FF';
        case 'refresh': return '#D9EDFF';
        default: return '#E2F5E9';
    }
});
var GridContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  \n  .react-grid-item {\n    background: #FFFFFF;\n    border: 1px solid #ECEAFF;\n    border-radius: 16px;\n    padding: 24px;\n    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);\n    transition: box-shadow 0.2s ease-in-out;\n\n    &:hover {\n      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);\n    }\n  }\n\n  .react-resizable-handle {\n    &::after {\n      content: '\u2198';\n      color: #ddd;\n      font-size: 14px;\n      position: absolute;\n      right: 8px;\n      bottom: 8px;\n      transition: color 0.2s ease;\n    }\n    \n    &:hover::after {\n      color: #7B61FF;\n    }\n  }\n"], ["\n  width: 100%;\n  \n  .react-grid-item {\n    background: #FFFFFF;\n    border: 1px solid #ECEAFF;\n    border-radius: 16px;\n    padding: 24px;\n    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);\n    transition: box-shadow 0.2s ease-in-out;\n\n    &:hover {\n      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);\n    }\n  }\n\n  .react-resizable-handle {\n    &::after {\n      content: '\u2198';\n      color: #ddd;\n      font-size: 14px;\n      position: absolute;\n      right: 8px;\n      bottom: 8px;\n      transition: color 0.2s ease;\n    }\n    \n    &:hover::after {\n      color: #7B61FF;\n    }\n  }\n"])));
var ChartContent = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  height: 400px;\n  background: #FFFFFF;\n  border-radius: 8px;\n  margin-bottom: 16px;\n  padding: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: 100%;\n  height: 400px;\n  background: #FFFFFF;\n  border-radius: 8px;\n  margin-bottom: 16px;\n  padding: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
var GridItemContent = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  height: 100%;\n  width: 100%;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  \n  ", " {\n    flex: 1;\n    min-height: 0;\n  }\n"], ["\n  height: 100%;\n  width: 100%;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  \n  ", " {\n    flex: 1;\n    min-height: 0;\n  }\n"])), ChartContent);
var ChartCard = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 16px;\n  padding: 24px;\n  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);\n  transition: transform 0.2s ease-in-out;\n\n  &:hover {\n    transform: translateY(-4px);\n    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);\n  }\n"], ["\n  background: #FFFFFF;\n  border: 1px solid #ECEAFF;\n  border-radius: 16px;\n  padding: 24px;\n  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);\n  transition: transform 0.2s ease-in-out;\n\n  &:hover {\n    transform: translateY(-4px);\n    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);\n  }\n"])));
var ChartTitle = styled.h3(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 18px;\n  color: #000000;\n  margin-bottom: 24px;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 18px;\n  color: #000000;\n  margin-bottom: 24px;\n"])));
var DetailButton = styled.button(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: auto;\n  min-width: 120px;\n  padding: 6px;\n  background: #F3F0FF;\n  border: none;\n  border-radius: 8px;\n  color: #999;\n  font-family: 'Inter';\n  font-size: 12px;\n  cursor: pointer;\n  opacity: 0.7;\n  transition: all 0.2s ease;\n  margin: 12px auto 0;\n  text-align: center;\n\n  &:hover {\n    opacity: 1;\n    color: #7B61FF;\n    background: #E9E3FF;\n  }\n"], ["\n  width: auto;\n  min-width: 120px;\n  padding: 6px;\n  background: #F3F0FF;\n  border: none;\n  border-radius: 8px;\n  color: #999;\n  font-family: 'Inter';\n  font-size: 12px;\n  cursor: pointer;\n  opacity: 0.7;\n  transition: all 0.2s ease;\n  margin: 12px auto 0;\n  text-align: center;\n\n  &:hover {\n    opacity: 1;\n    color: #7B61FF;\n    background: #E9E3FF;\n  }\n"])));
var CustomTooltip = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  background: rgba(255, 255, 255, 0.95);\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  padding: 12px;\n  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n\n  .label {\n    font-family: 'Inter';\n    font-weight: 600;\n    font-size: 14px;\n    color: #000;\n    margin-bottom: 4px;\n  }\n\n  .value {\n    font-family: 'Inter';\n    font-size: 13px;\n    color: #666;\n  }\n"], ["\n  background: rgba(255, 255, 255, 0.95);\n  border: 1px solid #ECEAFF;\n  border-radius: 8px;\n  padding: 12px;\n  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n\n  .label {\n    font-family: 'Inter';\n    font-weight: 600;\n    font-size: 14px;\n    color: #000;\n    margin-bottom: 4px;\n  }\n\n  .value {\n    font-family: 'Inter';\n    font-size: 13px;\n    color: #666;\n  }\n"])));
var FilterOperator;
(function (FilterOperator) {
    FilterOperator["EQUALS"] = "equals";
    FilterOperator["CONTAINS"] = "contains";
    FilterOperator["GREATER_THAN"] = "greater_than";
    FilterOperator["LESS_THAN"] = "less_than";
    FilterOperator["BETWEEN"] = "between";
})(FilterOperator || (FilterOperator = {}));
var COLORS = {
    primary: ['#8884d8', '#82ca9d', '#ffc658', '#ff8042'],
    secondary: ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'],
    accent: ['#8884d8', '#4CAF50', '#ff7300']
};
var CustomChartTooltip = function (_a) {
    var active = _a.active, payload = _a.payload, label = _a.label;
    if (active && payload && payload.length) {
        return (React.createElement(CustomTooltip, null,
            React.createElement("p", { className: "label" }, label),
            React.createElement("p", { className: "value" }, "Value: ".concat(payload[0].value))));
    }
    return null;
};
var ChartType;
(function (ChartType) {
    ChartType["BAR"] = "BAR";
    ChartType["LINE"] = "LINE";
    ChartType["PIE"] = "PIE";
    ChartType["AREA"] = "AREA";
})(ChartType || (ChartType = {}));
var calculateYValue = function (data, xAxis, yConfig) {
    // Group data by x-axis values with explicit typing
    var groupedData = data.reduce(function (acc, row) {
        var xValue = String(row[xAxis]);
        if (!acc[xValue]) {
            acc[xValue] = [];
        }
        var yValue = Number(row[yConfig.column]);
        if (!isNaN(yValue)) {
            acc[xValue].push(yValue);
        }
        return acc;
    }, {});
    // Apply calculation for each group
    return Object.entries(groupedData).map(function (_a) {
        var label = _a[0], values = _a[1];
        var value = 0;
        switch (yConfig.calculation.toLowerCase()) {
            case 'sum':
                value = values.reduce(function (sum, v) { return sum + v; }, 0);
                break;
            case 'average':
                value = values.reduce(function (sum, v) { return sum + v; }, 0) / values.length;
                break;
            case 'count':
                value = values.length;
                break;
            case 'min':
                value = Math.min.apply(Math, values);
                break;
            case 'max':
                value = Math.max.apply(Math, values);
                break;
            default:
                value = values[0] || 0;
        }
        return { label: label, value: value };
    });
};
var CancelButton = styled.button(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  background: #ff4d4f;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  padding: 8px 16px;\n  margin-top: 16px;\n  cursor: pointer;\n  font-size: 14px;\n  transition: all 0.2s;\n\n  &:hover {\n    background: #ff7875;\n  }\n"], ["\n  background: #ff4d4f;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  padding: 8px 16px;\n  margin-top: 16px;\n  cursor: pointer;\n  font-size: 14px;\n  transition: all 0.2s;\n\n  &:hover {\n    background: #ff7875;\n  }\n"])));
var LoadingOverlayContent = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n"])));
var LoadingOverlay = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 100000;\n  color: white;\n  font-size: 18px;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 100000;\n  color: white;\n  font-size: 18px;\n"])));
var applyFilters = function (data, filters) {
    return data.filter(function (row) {
        return filters.every(function (filter) {
            var value = row[filter.column];
            var filterValue = filter.value;
            switch (filter.operator) {
                case FilterOperator.EQUALS:
                    return String(value) === filterValue;
                case FilterOperator.CONTAINS:
                    return String(value).toLowerCase().includes(filterValue.toLowerCase());
                case FilterOperator.GREATER_THAN:
                    return Number(value) > Number(filterValue);
                case FilterOperator.LESS_THAN:
                    return Number(value) < Number(filterValue);
                case FilterOperator.BETWEEN:
                    var _a = filterValue.split(',').map(Number), min = _a[0], max = _a[1];
                    return Number(value) >= min && Number(value) <= max;
                default:
                    return true;
            }
        });
    });
};
var LoadingSpinner = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  border: 3px solid #f3f3f3;\n  border-top: 3px solid #8F4ACF;\n  border-radius: 50%;\n  width: 20px;\n  height: 20px;\n  animation: spin 1s linear infinite;\n  margin-right: 8px;\n\n  @keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }\n"], ["\n  border: 3px solid #f3f3f3;\n  border-top: 3px solid #8F4ACF;\n  border-radius: 50%;\n  width: 20px;\n  height: 20px;\n  animation: spin 1s linear infinite;\n  margin-right: 8px;\n\n  @keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }\n"])));
var DragHandle = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  width: 100%;\n  height: 24px;\n  cursor: move;\n  margin-bottom: 8px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #ddd;\n  \n  &::before {\n    content: '\u22EF';\n    font-size: 20px;\n  }\n  \n  &:hover {\n    color: #7B61FF;\n  }\n"], ["\n  width: 100%;\n  height: 24px;\n  cursor: move;\n  margin-bottom: 8px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #ddd;\n  \n  &::before {\n    content: '\u22EF';\n    font-size: 20px;\n  }\n  \n  &:hover {\n    color: #7B61FF;\n  }\n"])));
var QuestionsDashboard = function () {
    var folderId = useParams().folderId;
    var _a = useState([]), questions = _a[0], setQuestions = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState(false), exporting = _c[0], setExporting = _c[1];
    var navigate = useNavigate();
    var _d = useState(null), abortController = _d[0], setAbortController = _d[1];
    var _e = useState(false), refreshing = _e[0], setRefreshing = _e[1];
    var _f = useState(0), refreshProgress = _f[0], setRefreshProgress = _f[1];
    var _g = useState(null), exportType = _g[0], setExportType = _g[1];
    var _h = useState([]), layouts = _h[0], setLayouts = _h[1];
    var _j = useState(''), folderName = _j[0], setFolderName = _j[1];
    var fetchFolderName = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    return [4 /*yield*/, fetch("/api/folders/".concat(folderId, "/"), {
                            credentials: 'include'
                        })];
                case 1:
                    response = _a.sent();
                    if (!response.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    setFolderName(data.folder.name);
                    _a.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    console.error('Error fetching folder name:', error_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchFolderQuestions();
        fetchFolderName();
    }, [folderId]);
    useEffect(function () {
        var savedLayout = localStorage.getItem("dashboard-layout-".concat(folderId));
        if (savedLayout) {
            setLayouts(JSON.parse(savedLayout));
        }
    }, [folderId]);
    useEffect(function () {
        if (questions.length > 0 && layouts.length === 0) {
            // Initial layout setup
            var defaultLayouts = questions.map(function (_, index) { return ({
                i: index.toString(),
                x: 0,
                y: index * 12, // Increased vertical spacing
                w: 12, // Full width
                h: 11, // Increased height for better visibility
                minW: 4, // Larger minimum width
                minH: 6, // Larger minimum height
                static: false
            }); });
            setLayouts(defaultLayouts);
        }
        else if (questions.length > layouts.length) {
            // When new questions are added
            var lastLayout = layouts[layouts.length - 1];
            var lastY_1 = lastLayout ? lastLayout.y + lastLayout.h : 0;
            var newLayouts = __spreadArray(__spreadArray([], layouts, true), questions.slice(layouts.length).map(function (_, idx) { return ({
                i: (layouts.length + idx).toString(),
                x: 0,
                y: lastY_1 + (idx * 12), // Position below last chart with spacing
                w: 12, // Full width
                h: 11, // Taller default height
                minW: 4, // Larger minimum width
                minH: 6, // Larger minimum height
                static: false
            }); }), true);
            setLayouts(newLayouts);
            localStorage.setItem("dashboard-layout-".concat(folderId), JSON.stringify(newLayouts));
        }
    }, [questions, layouts.length, folderId]);
    var fetchFolderQuestions = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, questionsWithData, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, 5, 6]);
                    return [4 /*yield*/, fetch("/api/questions/favorites/", {
                            credentials: 'include'
                        })];
                case 1:
                    response = _a.sent();
                    if (!response.ok)
                        throw new Error('Failed to fetch folder questions');
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    return [4 /*yield*/, Promise.all(data.questions.map(function (question) { return __awaiter(void 0, void 0, void 0, function () {
                            var _a, configResponse, analysisResponse, config, _b, analysisResult, _c;
                            return __generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0: return [4 /*yield*/, Promise.all([
                                            fetch("/api/visualization-config/".concat(question.id, "/"), {
                                                credentials: 'include'
                                            }),
                                            fetch("/api/analysis-results/".concat(question.id, "/"), {
                                                credentials: 'include'
                                            })
                                        ])];
                                    case 1:
                                        _a = _d.sent(), configResponse = _a[0], analysisResponse = _a[1];
                                        if (!configResponse.ok) return [3 /*break*/, 3];
                                        return [4 /*yield*/, configResponse.json()];
                                    case 2:
                                        _b = _d.sent();
                                        return [3 /*break*/, 4];
                                    case 3:
                                        _b = null;
                                        _d.label = 4;
                                    case 4:
                                        config = _b;
                                        if (!analysisResponse.ok) return [3 /*break*/, 6];
                                        return [4 /*yield*/, analysisResponse.json()];
                                    case 5:
                                        _c = _d.sent();
                                        return [3 /*break*/, 7];
                                    case 6:
                                        _c = null;
                                        _d.label = 7;
                                    case 7:
                                        analysisResult = _c;
                                        return [2 /*return*/, {
                                                id: question.id,
                                                question: question.question,
                                                config: config,
                                                analysisResult: analysisResult
                                            }];
                                }
                            });
                        }); }))];
                case 3:
                    questionsWithData = _a.sent();
                    setQuestions(questionsWithData);
                    return [3 /*break*/, 6];
                case 4:
                    error_2 = _a.sent();
                    console.error('Error fetching questions data:', error_2);
                    toast.error('Failed to load questions data');
                    return [3 /*break*/, 6];
                case 5:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var renderChart = function (questionData) {
        var config = questionData.config, analysisResult = questionData.analysisResult;
        if (!config || !(analysisResult === null || analysisResult === void 0 ? void 0 : analysisResult.full_data))
            return null;
        // Apply filters to the data
        var filteredData = applyFilters(analysisResult.full_data, config.filters || []);
        // Calculate chart data based on configuration
        var chartData = calculateYValue(filteredData, config.x_axis, {
            column: config.y_axis_column,
            calculation: config.y_axis_calculation
        });
        // Sort data by value in descending order
        var sortedData = __spreadArray([], chartData, true).sort(function (a, b) { return b.value - a.value; });
        var commonProps = {
            data: sortedData,
            margin: { top: 20, right: 30, left: 20, bottom: 70 }
        };
        // Get Y-axis label based on calculation
        var getYAxisLabel = function () {
            var calc = config.y_axis_calculation.toLowerCase();
            var column = config.y_axis_column;
            return calc === 'none' ? column : "".concat(calc, " of ").concat(column);
        };
        switch (config.chart_type.toUpperCase()) {
            case 'LINE':
                return (React.createElement(ResponsiveContainer, { width: "100%", height: "100%" },
                    React.createElement(LineChart, __assign({}, commonProps),
                        React.createElement(CartesianGrid, { strokeDasharray: "3 3", stroke: "#f0f0f0" }),
                        React.createElement(XAxis, { dataKey: "label", angle: -45, textAnchor: "end", height: 70, interval: 0, tick: { fontSize: 12 } }),
                        React.createElement(YAxis, { tick: { fontSize: 12 }, tickFormatter: function (value) { return value.toLocaleString(); }, label: { value: getYAxisLabel(), angle: -90, position: 'insideLeft' } }),
                        React.createElement(Tooltip, { content: React.createElement(CustomChartTooltip, null) }),
                        React.createElement(Line, { type: "monotone", dataKey: "value", stroke: "#8884d8", strokeWidth: 2, dot: { fill: '#8884d8' } }))));
            case 'PIE':
                // Sort data by value and get top 10
                var sortedPieData = __spreadArray([], sortedData, true).sort(function (a, b) { return b.value - a.value; });
                var topData = sortedPieData.slice(0, 10);
                var otherData = sortedPieData.slice(10);
                // Calculate total for "Others"
                var pieData = otherData.length > 0
                    ? __spreadArray(__spreadArray([], topData, true), [{
                            label: "Others (".concat(otherData.length, " more)"),
                            value: otherData.reduce(function (sum, item) { return sum + Number(item.value); }, 0)
                        }], false) : topData;
                return (React.createElement(ResponsiveContainer, { width: "100%", height: 400 },
                    React.createElement(PieChart, { margin: { top: 20, right: 20, bottom: 20, left: 20 } },
                        React.createElement(Pie, { data: pieData, dataKey: "value", nameKey: "label", cx: "50%", cy: "50%", label: function (_a) {
                                var name = _a.name, percent = _a.percent;
                                return "".concat(name, " (").concat((percent * 100).toFixed(0), "%)");
                            } }, pieData.map(function (entry, index) { return (React.createElement(Cell, { key: "cell-".concat(index), fill: entry.label.includes('Others') ? '#999999' : COLORS.primary[index % COLORS.primary.length] })); })),
                        React.createElement(Tooltip, { content: function (_a) {
                                var active = _a.active, payload = _a.payload;
                                if (active && payload && payload.length) {
                                    var data = payload[0];
                                    var isOthers = data.payload.label.includes('Others');
                                    return (React.createElement(CustomTooltip, null,
                                        React.createElement("p", { className: "label" }, data.payload.label),
                                        React.createElement("p", { className: "value" }, isOthers
                                            ? "Total: ".concat(Number(data.value).toLocaleString())
                                            : "Value: ".concat(Number(data.value).toLocaleString()))));
                                }
                                return null;
                            } }))));
            case 'AREA':
                return (React.createElement(ResponsiveContainer, { width: "100%", height: "100%" },
                    React.createElement(AreaChart, __assign({}, commonProps),
                        React.createElement("defs", null,
                            React.createElement("linearGradient", { id: "colorValue", x1: "0", y1: "0", x2: "0", y2: "1" },
                                React.createElement("stop", { offset: "5%", stopColor: "#8884d8", stopOpacity: 0.8 }),
                                React.createElement("stop", { offset: "95%", stopColor: "#8884d8", stopOpacity: 0.1 }))),
                        React.createElement(CartesianGrid, { strokeDasharray: "3 3", stroke: "#f0f0f0" }),
                        React.createElement(XAxis, { dataKey: "label", angle: -45, textAnchor: "end", height: 70, interval: 0, tick: { fontSize: 12 } }),
                        React.createElement(YAxis, { tick: { fontSize: 12 }, tickFormatter: function (value) { return value.toLocaleString(); }, label: { value: getYAxisLabel(), angle: -90, position: 'insideLeft' } }),
                        React.createElement(Tooltip, { content: React.createElement(CustomChartTooltip, null) }),
                        React.createElement(Area, { type: "monotone", dataKey: "value", stroke: "#8884d8", fill: "url(#colorValue)" }))));
            case 'BAR':
            default:
                return (React.createElement(ResponsiveContainer, { width: "100%", height: "100%" },
                    React.createElement(BarChart, __assign({}, commonProps),
                        React.createElement(CartesianGrid, { strokeDasharray: "3 3", stroke: "#f0f0f0" }),
                        React.createElement(XAxis, { dataKey: "label", angle: -45, textAnchor: "end", height: 70, interval: 0, tick: { fontSize: 12 } }),
                        React.createElement(YAxis, { tick: { fontSize: 12 }, tickFormatter: function (value) { return value.toLocaleString(); }, label: { value: getYAxisLabel(), angle: -90, position: 'insideLeft' } }),
                        React.createElement(Tooltip, { content: React.createElement(CustomChartTooltip, null) }),
                        React.createElement(Bar, { dataKey: "value", fill: "#8884d8", radius: [4, 4, 0, 0] }, sortedData.map(function (entry, index) { return (React.createElement(Cell, { key: "cell-".concat(index), fill: COLORS.primary[index % COLORS.primary.length] })); })))));
        }
    };
    var handleExportToSheetsList = function () { return __awaiter(void 0, void 0, void 0, function () {
        var controller, csrfToken, response, data, error_3;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, 4, 5]);
                    setExporting(true);
                    setExportType('sheets');
                    controller = new AbortController();
                    setAbortController(controller);
                    csrfToken = (_a = document.cookie
                        .split('; ')
                        .find(function (row) { return row.startsWith('csrftoken='); })) === null || _a === void 0 ? void 0 : _a.split('=')[1];
                    return [4 /*yield*/, fetch('/api/export-to-sheets-list/', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': csrfToken || '',
                            },
                            credentials: 'include',
                            body: JSON.stringify({
                                questions: questions.map(function (q) { return ({
                                    id: q.id,
                                    question: q.question
                                }); })
                            }),
                            signal: controller.signal // Add the abort signal
                        })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _b.sent();
                    if (!response.ok) {
                        throw new Error(data.error || 'Failed to export to sheets');
                    }
                    if (data.spreadsheet_url) {
                        window.open(data.spreadsheet_url, '_blank');
                        toast.success('Successfully exported to Google Sheets');
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_3 = _b.sent();
                    // Check if the error was from cancellation
                    if (error_3 instanceof Error && error_3.name === 'AbortError') {
                        toast('Export cancelled', {
                            duration: 2000
                        });
                    }
                    else {
                        console.error('Error exporting to sheets:', error_3);
                        toast.error(error_3 instanceof Error ? error_3.message : 'Failed to export to sheets');
                    }
                    return [3 /*break*/, 5];
                case 4:
                    setExporting(false);
                    setExportType(null);
                    setAbortController(null);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleExportToSlidesList = function () { return __awaiter(void 0, void 0, void 0, function () {
        var controller, csrfToken, response, data, error_4;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, 4, 5]);
                    setExporting(true);
                    setExportType('slides');
                    controller = new AbortController();
                    setAbortController(controller);
                    csrfToken = (_a = document.cookie
                        .split('; ')
                        .find(function (row) { return row.startsWith('csrftoken='); })) === null || _a === void 0 ? void 0 : _a.split('=')[1];
                    return [4 /*yield*/, fetch('/api/export-to-slides-list/', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': csrfToken || '',
                            },
                            credentials: 'include',
                            body: JSON.stringify({
                                questions: questions.map(function (q) { return ({
                                    id: q.id,
                                    question: q.question
                                }); })
                            }),
                            signal: controller.signal
                        })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _b.sent();
                    if (!response.ok) {
                        throw new Error(data.error || 'Failed to export to slides');
                    }
                    if (data.slides_url) {
                        window.open(data.slides_url, '_blank');
                        toast.success('Successfully exported to Google Slides');
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_4 = _b.sent();
                    if (error_4 instanceof Error && error_4.name === 'AbortError') {
                        toast('Export cancelled', {
                            duration: 2000
                        });
                    }
                    else {
                        console.error('Error exporting to slides:', error_4);
                        toast.error(error_4 instanceof Error ? error_4.message : 'Failed to export to slides');
                    }
                    return [3 /*break*/, 5];
                case 4:
                    setExporting(false);
                    setExportType(null);
                    setAbortController(null);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleCancelExport = function () {
        if (abortController) {
            abortController.abort();
        }
    };
    var handleRefreshAll = function () { return __awaiter(void 0, void 0, void 0, function () {
        var csrfToken, i, question, response, errorData, result, error_5;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!questions.length)
                        return [2 /*return*/];
                    setRefreshing(true);
                    setRefreshProgress(0);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 10, 11, 12]);
                    csrfToken = (_a = document.cookie
                        .split('; ')
                        .find(function (row) { return row.startsWith('csrftoken='); })) === null || _a === void 0 ? void 0 : _a.split('=')[1];
                    i = 0;
                    _b.label = 2;
                case 2:
                    if (!(i < questions.length)) return [3 /*break*/, 8];
                    question = questions[i];
                    return [4 /*yield*/, fetch("/api/rerun-analysis/".concat(question.id, "/"), {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': csrfToken || '',
                            },
                            credentials: 'include'
                        })];
                case 3:
                    response = _b.sent();
                    if (!!response.ok) return [3 /*break*/, 5];
                    return [4 /*yield*/, response.json()];
                case 4:
                    errorData = _b.sent();
                    throw new Error(errorData.error || "Failed to refresh analysis for question ".concat(question.id));
                case 5: return [4 /*yield*/, response.json()];
                case 6:
                    result = _b.sent();
                    if (result.status === 'success') {
                        // Update progress
                        setRefreshProgress(((i + 1) / questions.length) * 100);
                    }
                    else {
                        throw new Error(result.error || "Analysis failed for question ".concat(question.id));
                    }
                    _b.label = 7;
                case 7:
                    i++;
                    return [3 /*break*/, 2];
                case 8: 
                // Fetch updated data after all refreshes are complete
                return [4 /*yield*/, fetchFolderQuestions()];
                case 9:
                    // Fetch updated data after all refreshes are complete
                    _b.sent();
                    toast.success('Successfully refreshed all analyses');
                    return [3 /*break*/, 12];
                case 10:
                    error_5 = _b.sent();
                    console.error('Error refreshing analyses:', error_5);
                    toast.error(error_5 instanceof Error ? error_5.message : 'Failed to refresh analyses');
                    return [3 /*break*/, 12];
                case 11:
                    setRefreshing(false);
                    setRefreshProgress(0);
                    return [7 /*endfinally*/];
                case 12: return [2 /*return*/];
            }
        });
    }); };
    var handleLayoutChange = function (newLayout) {
        setLayouts(newLayout);
        localStorage.setItem("dashboard-layout-".concat(folderId), JSON.stringify(newLayout));
    };
    if (loading) {
        return React.createElement("div", null, "Loading questions data...");
    }
    return (React.createElement(Container, null,
        exporting && (React.createElement(LoadingOverlay, null,
            React.createElement(LoadingOverlayContent, null,
                React.createElement("div", null,
                    "Exporting to Google ",
                    exportType === 'sheets' ? 'Sheets' : 'Slides',
                    "..."),
                React.createElement(CancelButton, { onClick: handleCancelExport }, "Cancel Export")))),
        React.createElement(Header, null,
            React.createElement(Title, null, folderName ? "".concat(folderName, " Folder") : 'Favorite Questions Dashboard'),
            React.createElement(ButtonGroup, null,
                React.createElement(ExportButton, { variant: "refresh", onClick: handleRefreshAll, disabled: refreshing || exporting }, refreshing ? (React.createElement(React.Fragment, null,
                    React.createElement(LoadingSpinner, null), "Refreshing ".concat(Math.round(refreshProgress), "%"))) : (React.createElement(React.Fragment, null,
                    React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", stroke: "currentColor" },
                        React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2, d: "M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" })),
                    "Refresh All"))),
                React.createElement(ExportButton, { variant: "sheets", onClick: handleExportToSheetsList, disabled: exporting || refreshing },
                    React.createElement("span", null),
                    " Export to Sheets"),
                React.createElement(ExportButton, { variant: "slides", onClick: handleExportToSlidesList, disabled: exporting || refreshing },
                    React.createElement("span", null),
                    " Export to Slides"))),
        React.createElement(ReactGridLayout, { className: "layout", layout: layouts, cols: 12, rowHeight: 60, onLayoutChange: handleLayoutChange, isDraggable: true, isResizable: true, draggableHandle: ".drag-handle", margin: [20, 20], containerPadding: [20, 20], useCSSTransforms: true, preventCollision: false, compactType: "vertical" }, questions.map(function (questionData, index) { return (React.createElement("div", { key: index.toString(), style: { height: '100%', display: 'flex', flexDirection: 'column' } },
            React.createElement(DragHandle, { className: "drag-handle" }),
            React.createElement(ChartTitle, null, questionData.question),
            React.createElement("div", { style: { flex: 1, minHeight: 0 } }, renderChart(questionData) || React.createElement("div", null, "No chart data available")),
            React.createElement(DetailButton, { onClick: function () { return navigate("/app/analysis/result/".concat(questionData.id)); }, style: { marginTop: 'auto' } }, "View Detailed Analysis"))); }))));
};
export default QuestionsDashboard;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getCsrfToken } from '../../utils/cookies';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"], ["\n  padding: 40px;\n  max-width: 1400px;\n  margin: 0 auto;\n  width: 100%;\n  overflow-x: hidden;\n  box-sizing: border-box;\n"])));
var Title = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 32px;\n  color: #000000;\n  margin-bottom: 40px;\n  position: relative;\n  \n  &:after {\n    content: '';\n    position: absolute;\n    bottom: -10px;\n    left: 0;\n    width: 60px;\n    height: 3px;\n    background: #8F4ACF;\n    border-radius: 2px;\n  }\n"])));
var DepartmentsSection = styled.section(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"], ["\n  background: #FFFFFF;\n  border-radius: 16px;\n  padding: 32px;\n  margin-bottom: 32px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);\n"])));
var CreateButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  margin-bottom: 24px;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"], ["\n  background: #8F4ACF;\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 12px 24px;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  margin-bottom: 24px;\n  \n  &:hover {\n    background: #7B3AAF;\n  }\n"])));
var Table = styled.table(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 16px;\n"], ["\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 16px;\n"])));
var Th = styled.th(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 12px;\n  text-align: left;\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 16px;\n  color: #000000;\n"], ["\n  padding: 12px;\n  text-align: left;\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 16px;\n  color: #000000;\n"])));
var Td = styled.td(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 12px;\n  text-align: left;\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #000000;\n"], ["\n  padding: 12px;\n  text-align: left;\n  font-family: 'Inter';\n  font-size: 14px;\n  color: #000000;\n"])));
var ActionButton = styled.button(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background: ", ";\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 8px 16px;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  margin-right: 8px;\n  \n  &:hover {\n    background: ", ";\n  }\n"], ["\n  background: ", ";\n  color: white;\n  border: none;\n  border-radius: 8px;\n  padding: 8px 16px;\n  font-family: 'Inter';\n  font-size: 14px;\n  cursor: pointer;\n  margin-right: 8px;\n  \n  &:hover {\n    background: ", ";\n  }\n"])), function (props) { return props.variant === 'edit' ? '#8F4ACF' : '#FF4D4F'; }, function (props) { return props.variant === 'edit' ? '#7B3AAF' : '#D93025'; });
var Modal = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: ", ";\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  justify-content: center;\n  align-items: center;\n  z-index: 10000;\n"], ["\n  display: ", ";\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  justify-content: center;\n  align-items: center;\n  z-index: 10000;\n"])), function (props) { return props.isOpen ? 'flex' : 'none'; });
var ModalContent = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background: white;\n  padding: 32px;\n  border-radius: 16px;\n  width: 500px;\n  max-width: 90%;\n  max-height: 80vh;\n  overflow-y: auto;\n  position: relative;\n  z-index: 10000;\n"], ["\n  background: white;\n  padding: 32px;\n  border-radius: 16px;\n  width: 500px;\n  max-width: 90%;\n  max-height: 80vh;\n  overflow-y: auto;\n  position: relative;\n  z-index: 10000;\n"])));
var Form = styled.form(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  max-height: calc(80vh - 64px); /* Account for padding */\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  max-height: calc(80vh - 64px); /* Account for padding */\n"])));
var FormGroup = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"])));
var Input = styled.input(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  padding: 8px;\n  border: 1px solid #ECEAFF;\n  border-radius: 4px;\n"], ["\n  padding: 8px;\n  border: 1px solid #ECEAFF;\n  border-radius: 4px;\n"])));
var Textarea = styled.textarea(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  padding: 8px;\n  border: 1px solid #ECEAFF;\n  border-radius: 4px;\n  min-height: 100px;\n"], ["\n  padding: 8px;\n  border: 1px solid #ECEAFF;\n  border-radius: 4px;\n  min-height: 100px;\n"])));
var CheckboxGroup = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"])));
var DatasetList = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  margin-top: 16px;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  max-height: 400px;\n  overflow-y: auto;\n  padding: 16px;\n  background: #F8F9FA;\n  border-radius: 8px;\n  border: 1px solid #ECEAFF;\n"], ["\n  margin-top: 16px;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  max-height: 400px;\n  overflow-y: auto;\n  padding: 16px;\n  background: #F8F9FA;\n  border-radius: 8px;\n  border: 1px solid #ECEAFF;\n"])));
var DatasetItem = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  background: white;\n  border-radius: 8px;\n  border: 1px solid #ECEAFF;\n  padding: 16px;\n"], ["\n  background: white;\n  border-radius: 8px;\n  border: 1px solid #ECEAFF;\n  padding: 16px;\n"])));
var DatasetHeader = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  margin-bottom: 12px;\n  padding: 8px;\n  background: #F8F5FF;\n  border-radius: 6px;\n  cursor: pointer;\n\n  &:hover {\n    background: #F0E7FF;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  margin-bottom: 12px;\n  padding: 8px;\n  background: #F8F5FF;\n  border-radius: 6px;\n  cursor: pointer;\n\n  &:hover {\n    background: #F0E7FF;\n  }\n"])));
var DatasetName = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  font-weight: 600;\n  color: #2D3748;\n  font-size: 16px;\n"], ["\n  font-weight: 600;\n  color: #2D3748;\n  font-size: 16px;\n"])));
var TablesContainer = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));\n  gap: 12px;\n  margin-left: 28px;\n"], ["\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));\n  gap: 12px;\n  margin-left: 28px;\n"])));
var TableCheckbox = styled.input(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  width: 18px;\n  height: 18px;\n  margin-top: 2px;\n  cursor: pointer;\n  accent-color: #8F4ACF;\n"], ["\n  width: 18px;\n  height: 18px;\n  margin-top: 2px;\n  cursor: pointer;\n  accent-color: #8F4ACF;\n"])));
var ModalHeader = styled.div(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  position: sticky;\n  top: 0;\n  background: white;\n  padding-bottom: 16px;\n  margin-bottom: 16px;\n  border-bottom: 1px solid #ECEAFF;\n  z-index: 1;\n"], ["\n  position: sticky;\n  top: 0;\n  background: white;\n  padding-bottom: 16px;\n  margin-bottom: 16px;\n  border-bottom: 1px solid #ECEAFF;\n  z-index: 1;\n"])));
var TableCell = styled(Td)(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  ul {\n    margin: 0;\n    padding-left: 20px;\n    font-size: 12px;\n    color: #666;\n  }\n"], ["\n  ul {\n    margin: 0;\n    padding-left: 20px;\n    font-size: 12px;\n    color: #666;\n  }\n"])));
var TablesList = styled.div(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  max-height: 100px;\n  overflow-y: auto;\n"], ["\n  max-height: 100px;\n  overflow-y: auto;\n"])));
var AnotherTableItem = styled.div(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  // Different styles\n"], ["\n  // Different styles\n"])));
var SelectAllButton = styled.button(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  background: transparent;\n  color: #8F4ACF;\n  border: 1px solid #8F4ACF;\n  border-radius: 8px;\n  padding: 8px 16px;\n  font-size: 14px;\n  cursor: pointer;\n  margin-bottom: 16px;\n  transition: all 0.2s ease;\n  \n  &:hover {\n    background: #F8F5FF;\n    transform: translateY(-1px);\n  }\n"], ["\n  background: transparent;\n  color: #8F4ACF;\n  border: 1px solid #8F4ACF;\n  border-radius: 8px;\n  padding: 8px 16px;\n  font-size: 14px;\n  cursor: pointer;\n  margin-bottom: 16px;\n  transition: all 0.2s ease;\n  \n  &:hover {\n    background: #F8F5FF;\n    transform: translateY(-1px);\n  }\n"])));
var TableItem = styled.div(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  padding: 12px;\n  background: white;\n  border: 1px solid #ECEAFF;\n  border-radius: 6px;\n  transition: all 0.2s ease;\n  \n  &:hover {\n    background: #F8F5FF;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  padding: 12px;\n  background: white;\n  border: 1px solid #ECEAFF;\n  border-radius: 6px;\n  transition: all 0.2s ease;\n  \n  &:hover {\n    background: #F8F5FF;\n  }\n"])));
var TableInfo = styled.div(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n"], ["\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n"])));
var TableName = styled.div(templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n  font-weight: 500;\n  color: #2D3748;\n  font-size: 14px;\n"], ["\n  font-weight: 500;\n  color: #2D3748;\n  font-size: 14px;\n"])));
var DatasetCheckbox = styled.input(templateObject_30 || (templateObject_30 = __makeTemplateObject(["\n  width: 20px;\n  height: 20px;\n  margin-right: 12px;\n  cursor: pointer;\n  accent-color: #8F4ACF;\n"], ["\n  width: 20px;\n  height: 20px;\n  margin-right: 12px;\n  cursor: pointer;\n  accent-color: #8F4ACF;\n"])));
var ManageDepartments = function () {
    var _a = useState([]), departments = _a[0], setDepartments = _a[1];
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var _d = useState(false), isAddModalOpen = _d[0], setIsAddModalOpen = _d[1];
    var _e = useState(false), isEditModalOpen = _e[0], setIsEditModalOpen = _e[1];
    var _f = useState(null), selectedDepartment = _f[0], setSelectedDepartment = _f[1];
    var _g = useState({
        name: '',
        description: '',
        selectedTables: {}
    }), formData = _g[0], setFormData = _g[1];
    var _h = useState([]), datasets = _h[0], setDatasets = _h[1];
    var _j = useState([]), tables = _j[0], setTables = _j[1];
    var _k = useState({}), availableTables = _k[0], setAvailableTables = _k[1];
    var fetchDepartments = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    setIsLoading(true);
                    return [4 /*yield*/, fetch('/api/departments/manage/', {
                            headers: {
                                'X-CSRFToken': getCsrfToken(),
                            },
                        })];
                case 1:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error("Failed to fetch departments: ".concat(response.statusText));
                    }
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    if (data.status === 'success') {
                        setDepartments(data.departments);
                    }
                    else {
                        throw new Error(data.error || 'Failed to fetch departments');
                    }
                    return [3 /*break*/, 5];
                case 3:
                    err_1 = _a.sent();
                    console.error('Error fetching departments:', err_1);
                    setError(err_1 instanceof Error ? err_1.message : 'An error occurred');
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var fetchDatasets = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch('/api/datasets/all/')];
                case 1:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error("Failed to fetch datasets: ".concat(response.statusText));
                    }
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    setDatasets(data);
                    return [3 /*break*/, 4];
                case 3:
                    err_2 = _a.sent();
                    console.error('Error fetching datasets:', err_2);
                    setError(err_2 instanceof Error ? err_2.message : 'An error occurred');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var fetchTables = function (datasetId) { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, err_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch("/api/datasets/".concat(datasetId, "/tables/"))];
                case 1:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error('Failed to fetch tables');
                    }
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    setTables(data.tables);
                    return [3 /*break*/, 4];
                case 3:
                    err_3 = _a.sent();
                    setError(err_3 instanceof Error ? err_3.message : 'An error occurred');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleDatasetChange = function (e) {
        var datasetId = parseInt(e.target.value, 10);
        fetchTables(datasetId);
        handleInputChange(e); // Update form data
    };
    var fetchAvailableTables = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, tables_1, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch('/api/datasets/')];
                case 1:
                    response = _a.sent();
                    if (!response.ok)
                        throw new Error('Failed to fetch datasets');
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    tables_1 = {};
                    data.forEach(function (dataset) {
                        Object.entries(dataset.data.tables).forEach(function (_a) {
                            var tableName = _a[0], tableInfo = _a[1];
                            tables_1[tableName] = tableInfo;
                        });
                    });
                    setAvailableTables(tables_1);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error fetching available tables:', error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var loadInitialData = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            fetchDepartments(),
                            fetchDatasets(),
                            fetchAvailableTables()
                        ])];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        loadInitialData();
    }, []);
    var handleCreateDepartment = function () {
        setFormData({ name: '', description: '', selectedTables: {} });
        setIsAddModalOpen(true);
    };
    var handleEditDepartment = function (department) {
        setSelectedDepartment(department);
        setFormData({
            name: department.name,
            description: department.description,
            selectedTables: department.selected_tables || {}
        });
        setIsEditModalOpen(true);
    };
    var handleDeleteDepartment = function (departmentId) { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, err_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!window.confirm('Are you sure you want to delete this department?'))
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    return [4 /*yield*/, fetch("/api/departments/manage/".concat(departmentId, "/"), {
                            method: 'DELETE',
                            headers: {
                                'X-CSRFToken': getCsrfToken(),
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (!!response.ok) return [3 /*break*/, 4];
                    return [4 /*yield*/, response.json()];
                case 3:
                    data = _a.sent();
                    throw new Error(data.error || 'Failed to delete department');
                case 4: return [4 /*yield*/, fetchDepartments()];
                case 5:
                    _a.sent(); // Refresh the list
                    return [3 /*break*/, 7];
                case 6:
                    err_4 = _a.sent();
                    setError(err_4 instanceof Error ? err_4.message : 'Failed to delete department');
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var response, err_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, fetch(selectedDepartment
                            ? "/api/departments/manage/".concat(selectedDepartment.id, "/")
                            : '/api/departments/manage/', {
                            method: selectedDepartment ? 'PUT' : 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': getCsrfToken(),
                            },
                            body: JSON.stringify({
                                name: formData.name,
                                description: formData.description,
                                selectedTables: formData.selectedTables
                            }),
                        })];
                case 2:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error('Failed to save department');
                    }
                    fetchDepartments();
                    setIsEditModalOpen(false);
                    setIsAddModalOpen(false);
                    return [3 /*break*/, 4];
                case 3:
                    err_5 = _a.sent();
                    setError(err_5 instanceof Error ? err_5.message : 'An error occurred');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleInputChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        setFormData(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleSelectedTablesChange = function (departmentId, selectedTables) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, fetch("/api/departments/".concat(departmentId, "/tables/update/"), {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': getCsrfToken(),
                            },
                            body: JSON.stringify({ selected_tables: selectedTables }),
                        })];
                case 1:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error('Failed to update selected tables');
                    }
                    // Refresh departments list
                    fetchDepartments();
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    console.error('Error updating selected tables:', error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleSelectAllTables = function (selectAll) {
        if (selectAll) {
            var allTables_1 = {};
            datasets.forEach(function (dataset) {
                Object.keys(dataset.data.tables).forEach(function (tableName) {
                    allTables_1[tableName] = dataset.name;
                });
            });
            setFormData(function (prev) { return (__assign(__assign({}, prev), { selectedTables: allTables_1 })); });
        }
        else {
            setFormData(function (prev) { return (__assign(__assign({}, prev), { selectedTables: {} })); });
        }
    };
    var getTablesFromDataset = function (dataset) {
        return Object.keys(dataset.data.tables);
    };
    var handleDatasetSelection = function (dataset, isSelected) {
        var datasetTables = getTablesFromDataset(dataset);
        setFormData(function (prev) {
            var newSelectedTables = __assign({}, prev.selectedTables);
            if (isSelected) {
                // Add all tables from dataset
                datasetTables.forEach(function (table) {
                    newSelectedTables[table] = dataset.name;
                });
            }
            else {
                // Remove all tables from this dataset
                datasetTables.forEach(function (table) {
                    delete newSelectedTables[table];
                });
            }
            return __assign(__assign({}, prev), { selectedTables: newSelectedTables });
        });
    };
    var handleTableSelection = function (tableName, datasetName, checked) {
        setFormData(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), { selectedTables: __assign(__assign({}, prev.selectedTables), (_a = {}, _a[tableName] = checked ? datasetName : null, _a)) }));
        });
    };
    var isTableSelected = function (tableName, datasetName) {
        return formData.selectedTables[tableName] === datasetName;
    };
    var isDatasetSelected = function (dataset) {
        return getTablesFromDataset(dataset).every(function (tableName) {
            return formData.selectedTables[tableName] === dataset.name;
        });
    };
    if (isLoading)
        return React.createElement("div", null, "Loading...");
    if (error)
        return React.createElement("div", null,
            "Error: ",
            error);
    return (React.createElement(Container, null,
        React.createElement(Title, null, "Manage Departments"),
        React.createElement(DepartmentsSection, null,
            React.createElement(CreateButton, { onClick: handleCreateDepartment }, "Add New Department"),
            React.createElement(Table, null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement(Th, null, "Department Name"),
                        React.createElement(Th, null, "Description"),
                        React.createElement(Th, null, "Users"),
                        React.createElement(Th, null, "Requests"),
                        React.createElement(Th, null, "Actions"))),
                React.createElement("tbody", null, departments.map(function (department) { return (React.createElement("tr", { key: department.id },
                    React.createElement(Td, null, department.name),
                    React.createElement(Td, null, department.description),
                    React.createElement(Td, null, department.user_count),
                    React.createElement(Td, null, department.question_count),
                    React.createElement(Td, null,
                        React.createElement(ActionButton, { variant: "edit", onClick: function () { return handleEditDepartment(department); } }, "Edit"),
                        React.createElement(ActionButton, { variant: "delete", onClick: function () { return handleDeleteDepartment(department.id); } }, "Delete")))); }))),
            React.createElement(Modal, { isOpen: isAddModalOpen },
                React.createElement(ModalContent, null,
                    React.createElement("h2", null, "Add New Department"),
                    React.createElement(Form, { onSubmit: handleSubmit },
                        React.createElement(FormGroup, null,
                            React.createElement("label", { htmlFor: "name" }, "Department Name"),
                            React.createElement(Input, { type: "text", id: "name", name: "name", value: formData.name, onChange: handleInputChange, required: true })),
                        React.createElement(FormGroup, null,
                            React.createElement("label", { htmlFor: "description" }, "Description"),
                            React.createElement(Textarea, { id: "description", name: "description", value: formData.description, onChange: handleInputChange })),
                        React.createElement(FormGroup, null,
                            React.createElement("label", null, "Select Tables"),
                            React.createElement(SelectAllButton, { type: "button", onClick: function () { return handleSelectAllTables(Object.keys(formData.selectedTables).length === 0); } }, Object.keys(formData.selectedTables).length === 0 ? 'Select All Tables' : 'Deselect All Tables'),
                            React.createElement(DatasetList, null, datasets.map(function (dataset) { return (React.createElement(DatasetItem, { key: dataset.id },
                                React.createElement(DatasetHeader, null,
                                    React.createElement(DatasetCheckbox, { type: "checkbox", checked: isDatasetSelected(dataset), onChange: function (e) { return handleDatasetSelection(dataset, e.target.checked); } }),
                                    React.createElement(DatasetName, null, dataset.name)),
                                React.createElement(TablesContainer, null, Object.entries(dataset.data.tables).map(function (_a) {
                                    var tableName = _a[0], table = _a[1];
                                    return (React.createElement(TableItem, { key: tableName },
                                        React.createElement(TableCheckbox, { type: "checkbox", name: "selectedTables", value: tableName, checked: isTableSelected(tableName, dataset.name), onChange: function (e) { return handleTableSelection(tableName, dataset.name, e.target.checked); } }),
                                        React.createElement(TableInfo, null,
                                            React.createElement(TableName, null, tableName))));
                                })))); }))),
                        React.createElement("div", null,
                            React.createElement(ActionButton, { variant: "edit", type: "submit" }, "Create Department"),
                            React.createElement(ActionButton, { variant: "delete", type: "button", onClick: function () { return setIsAddModalOpen(false); } }, "Cancel"))))),
            React.createElement(Modal, { isOpen: isEditModalOpen },
                React.createElement(ModalContent, null,
                    React.createElement(ModalHeader, null,
                        React.createElement("h2", null, "Edit Department")),
                    React.createElement(Form, { onSubmit: handleSubmit },
                        React.createElement(FormGroup, null,
                            React.createElement("label", { htmlFor: "edit-name" }, "Department Name"),
                            React.createElement(Input, { type: "text", id: "edit-name", name: "name", value: formData.name, onChange: handleInputChange, required: true })),
                        React.createElement(FormGroup, null,
                            React.createElement("label", { htmlFor: "edit-description" }, "Description"),
                            React.createElement(Textarea, { id: "edit-description", name: "description", value: formData.description, onChange: handleInputChange })),
                        React.createElement(FormGroup, null,
                            React.createElement("label", null, "Select Tables"),
                            React.createElement(SelectAllButton, { type: "button", onClick: function () { return handleSelectAllTables(Object.keys(formData.selectedTables).length === 0); } }, Object.keys(formData.selectedTables).length === 0 ? 'Select All Tables' : 'Deselect All Tables'),
                            React.createElement(DatasetList, null, datasets.map(function (dataset) { return (React.createElement(DatasetItem, { key: dataset.id },
                                React.createElement(DatasetHeader, null,
                                    React.createElement(DatasetCheckbox, { type: "checkbox", checked: isDatasetSelected(dataset), onChange: function (e) { return handleDatasetSelection(dataset, e.target.checked); } }),
                                    React.createElement(DatasetName, null, dataset.name)),
                                React.createElement(TablesContainer, null, Object.entries(dataset.data.tables).map(function (_a) {
                                    var tableName = _a[0], table = _a[1];
                                    return (React.createElement(TableItem, { key: tableName },
                                        React.createElement(TableCheckbox, { type: "checkbox", name: "selectedTables", value: tableName, checked: isTableSelected(tableName, dataset.name), onChange: function (e) { return handleTableSelection(tableName, dataset.name, e.target.checked); } }),
                                        React.createElement(TableInfo, null,
                                            React.createElement(TableName, null, tableName))));
                                })))); }))),
                        React.createElement("div", null,
                            React.createElement(ActionButton, { variant: "edit", type: "submit" }, "Update Department"),
                            React.createElement(ActionButton, { variant: "delete", type: "button", onClick: function () { return setIsEditModalOpen(false); } }, "Cancel"))))))));
};
export default ManageDepartments;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29, templateObject_30;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import FooterMain from './Layout/footer_main';
import mission from '../assets/mission.png';
import vision from '../assets/vision.png';
import approach from '../assets/approach.png';
var PageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-height: 100vh;\n  background: #FFFFFF;\n  padding-top: 60px;\n  position: relative;\n  overflow: hidden;\n"], ["\n  min-height: 100vh;\n  background: #FFFFFF;\n  padding-top: 60px;\n  position: relative;\n  overflow: hidden;\n"])));
var MainContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 0 20px;\n  text-align: center;\n"], ["\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 0 20px;\n  text-align: center;\n"])));
var Title = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 58px;\n  font-weight: 700;\n  line-height: 106%;\n  margin-bottom: 24px;\n  color: #000000;\n\n  span {\n    background: linear-gradient(90deg, #A65CE8 0%, #7094F4 100%);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n  }\n"], ["\n  font-family: 'Inter';\n  font-size: 58px;\n  font-weight: 700;\n  line-height: 106%;\n  margin-bottom: 24px;\n  color: #000000;\n\n  span {\n    background: linear-gradient(90deg, #A65CE8 0%, #7094F4 100%);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n  }\n"])));
var Subtitle = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 18px;\n  line-height: 22px;\n  text-align: center;\n  color: #000000;\n  max-width: 761px;\n  margin: 0 auto 60px;\n\n  span {\n    color: #A65CE8;\n    position: relative;\n    \n    &::after {\n      content: '';\n      position: absolute;\n      bottom: -2px;\n      left: 0;\n      width: 100%;\n      height: 2px;\n      background: linear-gradient(90deg, #A65CE8 0%, #7094F4 100%);\n      transform: scaleX(0);\n      transition: transform 0.3s ease;\n    }\n    \n    &:hover::after {\n      transform: scaleX(1);\n    }\n  }\n"], ["\n  font-family: 'Inter';\n  font-size: 18px;\n  line-height: 22px;\n  text-align: center;\n  color: #000000;\n  max-width: 761px;\n  margin: 0 auto 60px;\n\n  span {\n    color: #A65CE8;\n    position: relative;\n    \n    &::after {\n      content: '';\n      position: absolute;\n      bottom: -2px;\n      left: 0;\n      width: 100%;\n      height: 2px;\n      background: linear-gradient(90deg, #A65CE8 0%, #7094F4 100%);\n      transform: scaleX(0);\n      transition: transform 0.3s ease;\n    }\n    \n    &:hover::after {\n      transform: scaleX(1);\n    }\n  }\n"])));
var ContentCard = styled(motion.div)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: #FFFFFF;\n  box-shadow: 16px 32px 74px rgba(28, 59, 168, 0.1);\n  border-radius: 30px;\n  padding: 60px;\n  margin-bottom: 80px;\n  position: relative;\n\n  &::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    height: 4px;\n    background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n    border-radius: 30px 30px 0 0;\n  }\n"], ["\n  background: #FFFFFF;\n  box-shadow: 16px 32px 74px rgba(28, 59, 168, 0.1);\n  border-radius: 30px;\n  padding: 60px;\n  margin-bottom: 80px;\n  position: relative;\n\n  &::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    height: 4px;\n    background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n    border-radius: 30px 30px 0 0;\n  }\n"])));
var ContentText = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 20px;\n  line-height: 1.6;\n  color: #000000;\n  text-align: left;\n\n  p {\n    margin-bottom: 24px;\n\n    &:last-child {\n      margin-bottom: 0;\n    }\n  }\n\n  span.highlight {\n    color: #7094F4;\n    font-weight: 500;\n    transition: color 0.3s ease;\n\n    &:hover {\n      color: #A65CE8;\n    }\n  }\n"], ["\n  font-family: 'Inter';\n  font-size: 20px;\n  line-height: 1.6;\n  color: #000000;\n  text-align: left;\n\n  p {\n    margin-bottom: 24px;\n\n    &:last-child {\n      margin-bottom: 0;\n    }\n  }\n\n  span.highlight {\n    color: #7094F4;\n    font-weight: 500;\n    transition: color 0.3s ease;\n\n    &:hover {\n      color: #A65CE8;\n    }\n  }\n"])));
var GridContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  gap: 30px;\n  margin-bottom: 80px;\n"], ["\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  gap: 30px;\n  margin-bottom: 80px;\n"])));
var FeatureCard = styled(motion.div)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background: #FFFFFF;\n  box-shadow: 16px 32px 74px rgba(28, 59, 168, 0.1);\n  border-radius: 30px;\n  padding: 40px;\n  text-align: center;\n  transition: all 0.3s ease;\n\n  &:hover {\n    transform: translateY(-10px);\n    box-shadow: 16px 32px 74px rgba(79, 28, 168, 0.15);\n  }\n"], ["\n  background: #FFFFFF;\n  box-shadow: 16px 32px 74px rgba(28, 59, 168, 0.1);\n  border-radius: 30px;\n  padding: 40px;\n  text-align: center;\n  transition: all 0.3s ease;\n\n  &:hover {\n    transform: translateY(-10px);\n    box-shadow: 16px 32px 74px rgba(79, 28, 168, 0.15);\n  }\n"])));
var IconWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 85px;\n  height: 85px;\n  margin: 0 auto 24px;\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n"], ["\n  width: 85px;\n  height: 85px;\n  margin: 0 auto 24px;\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n"])));
var CardTitle = styled.h3(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 110%;\n  color: #7094F4;\n  margin-bottom: 16px;\n  transition: all 0.3s ease;\n\n  ", ":hover & {\n    background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n  }\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 110%;\n  color: #7094F4;\n  margin-bottom: 16px;\n  transition: all 0.3s ease;\n\n  ", ":hover & {\n    background: linear-gradient(90deg, #8F4ACF 0%, #7094F4 100%);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n  }\n"])), FeatureCard);
var CardText = styled.p(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 16px;\n  line-height: 126%;\n  color: #000000;\n  max-width: 270px;\n  margin: 0 auto;\n"], ["\n  font-family: 'Inter';\n  font-size: 16px;\n  line-height: 126%;\n  color: #000000;\n  max-width: 270px;\n  margin: 0 auto;\n"])));
var BackgroundDecoration = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  position: absolute;\n  width: 600px;\n  height: 600px;\n  border-radius: 50%;\n  background: radial-gradient(circle, rgba(166, 92, 232, 0.1) 0%, rgba(166, 92, 232, 0) 70%);\n  z-index: -1;\n  \n  &.top-right {\n    top: -200px;\n    right: -200px;\n  }\n  \n  &.bottom-left {\n    bottom: -200px;\n    left: -200px;\n    background: radial-gradient(circle, rgba(112, 148, 244, 0.1) 0%, rgba(112, 148, 244, 0) 70%);\n  }\n"], ["\n  position: absolute;\n  width: 600px;\n  height: 600px;\n  border-radius: 50%;\n  background: radial-gradient(circle, rgba(166, 92, 232, 0.1) 0%, rgba(166, 92, 232, 0) 70%);\n  z-index: -1;\n  \n  &.top-right {\n    top: -200px;\n    right: -200px;\n  }\n  \n  &.bottom-left {\n    bottom: -200px;\n    left: -200px;\n    background: radial-gradient(circle, rgba(112, 148, 244, 0.1) 0%, rgba(112, 148, 244, 0) 70%);\n  }\n"])));
var AboutPage = function () {
    return (React.createElement(PageContainer, null,
        React.createElement(BackgroundDecoration, { className: "top-right" }),
        React.createElement(BackgroundDecoration, { className: "bottom-left" }),
        React.createElement(MainContent, null,
            React.createElement(Title, null,
                "About ",
                React.createElement("span", null, "Cognitio Intelligence")),
            React.createElement(Subtitle, null,
                "Empowering Businesses with ",
                React.createElement("span", null, "Advanced Data Analytics"),
                " and ",
                React.createElement("span", null, "AI Solutions")),
            React.createElement(ContentCard, { initial: { opacity: 0, y: 20 }, animate: { opacity: 1, y: 0 }, transition: { duration: 0.6 } },
                React.createElement(ContentText, null,
                    React.createElement("p", null,
                        "Cognitio Intelligence provides ",
                        React.createElement("span", { className: "highlight" }, "data analytics"),
                        " and ",
                        React.createElement("span", { className: "highlight" }, "artificial intelligence solutions"),
                        " tailored to meet the unique needs of businesses across various industries. Our goal is to help organizations harness the power of their data to drive growth and success."),
                    React.createElement("p", null, "At Cognitio, we focus on delivering value through:"),
                    React.createElement("p", null,
                        React.createElement("span", { className: "highlight" }, "Tailored Solutions:"),
                        " We craft analytics and AI strategies that align with your specific business goals and requirements."),
                    React.createElement("p", null,
                        React.createElement("span", { className: "highlight" }, "Cutting-Edge Technologies:"),
                        " Leveraging the latest tools and platforms, we ensure you stay ahead in an ever-changing digital world."),
                    React.createElement("p", null,
                        React.createElement("span", { className: "highlight" }, "Expert Guidance:"),
                        " Our team provides practical advice and innovative approaches to overcome data challenges."),
                    React.createElement("p", null,
                        React.createElement("span", { className: "highlight" }, "Continuous Support:"),
                        " We offer ongoing assistance and training to ensure sustainable growth and adaptability."),
                    React.createElement("p", null, "Our mission is to partner with you and deliver impactful solutions that make a real difference."))),
            React.createElement(GridContainer, null, [
                {
                    icon: mission,
                    title: "Our Mission",
                    text: "To democratize data intelligence by making advanced AI technologies accessible and intuitive for organizations of all sizes"
                },
                {
                    icon: vision,
                    title: "Our Vision",
                    text: "To become the leading platform for AI-powered data analysis, enabling businesses to make better, data-driven decisions"
                },
                {
                    icon: approach,
                    title: "Our Approach",
                    text: "Combining cutting-edge AI with user-friendly interfaces to analyze complex datasets and uncover meaningful patterns"
                }
            ].map(function (item, index) { return (React.createElement(FeatureCard, { key: index, initial: { opacity: 0, y: 20 }, whileInView: { opacity: 1, y: 0 }, viewport: { once: true }, transition: { delay: index * 0.1 } },
                React.createElement(IconWrapper, null,
                    React.createElement("img", { src: item.icon, alt: item.title })),
                React.createElement(CardTitle, null, item.title),
                React.createElement(CardText, null, item.text))); }))),
        React.createElement(FooterMain, null)));
};
export default AboutPage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;

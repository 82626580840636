var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import FooterMain from './Layout/footer_main';
var PageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-height: 100vh;\n  background: #FFFFFF;\n  padding-top: 60px;\n  position: relative;\n  overflow: hidden;\n"], ["\n  min-height: 100vh;\n  background: #FFFFFF;\n  padding-top: 60px;\n  position: relative;\n  overflow: hidden;\n"])));
var MainContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 0 20px;\n"], ["\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 0 20px;\n"])));
var Title = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 58px;\n  font-weight: 700;\n  line-height: 106%;\n  margin-bottom: 24px;\n  color: #000000;\n  text-align: center;\n\n  span {\n    background: linear-gradient(90deg, #A65CE8 0%, #7094F4 100%);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n  }\n"], ["\n  font-family: 'Inter';\n  font-size: 58px;\n  font-weight: 700;\n  line-height: 106%;\n  margin-bottom: 24px;\n  color: #000000;\n  text-align: center;\n\n  span {\n    background: linear-gradient(90deg, #A65CE8 0%, #7094F4 100%);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n  }\n"])));
var Subtitle = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 18px;\n  line-height: 22px;\n  text-align: center;\n  color: #000000;\n  max-width: 761px;\n  margin: 0 auto 60px;\n"], ["\n  font-family: 'Inter';\n  font-size: 18px;\n  line-height: 22px;\n  text-align: center;\n  color: #000000;\n  max-width: 761px;\n  margin: 0 auto 60px;\n"])));
var ContentSection = styled(motion.section)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: rgba(255, 255, 255, 0.6);\n  box-shadow: 16px 32px 74px rgba(28, 59, 168, 0.1);\n  border-radius: 30px;\n  padding: 40px;\n  margin-bottom: 40px;\n  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);\n\n  &:hover {\n    transform: translateY(-5px);\n    box-shadow: 0 30px 60px rgba(28, 59, 168, 0.15);\n  }\n"], ["\n  background: rgba(255, 255, 255, 0.6);\n  box-shadow: 16px 32px 74px rgba(28, 59, 168, 0.1);\n  border-radius: 30px;\n  padding: 40px;\n  margin-bottom: 40px;\n  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);\n\n  &:hover {\n    transform: translateY(-5px);\n    box-shadow: 0 30px 60px rgba(28, 59, 168, 0.15);\n  }\n"])));
var SectionTitle = styled.h2(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #7094F4;\n  margin-bottom: 20px;\n"], ["\n  font-family: 'Inter';\n  font-weight: 600;\n  font-size: 24px;\n  color: #7094F4;\n  margin-bottom: 20px;\n"])));
var SectionText = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-family: 'Inter';\n  font-size: 16px;\n  line-height: 1.6;\n  color: #666666;\n  margin-bottom: 20px;\n"], ["\n  font-family: 'Inter';\n  font-size: 16px;\n  line-height: 1.6;\n  color: #666666;\n  margin-bottom: 20px;\n"])));
var BackgroundDecoration = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: absolute;\n  width: 600px;\n  height: 600px;\n  border-radius: 50%;\n  background: radial-gradient(circle, rgba(166, 92, 232, 0.1) 0%, rgba(166, 92, 232, 0) 70%);\n  z-index: -1;\n  \n  &.top-right {\n    top: -200px;\n    right: -200px;\n  }\n  \n  &.bottom-left {\n    bottom: -200px;\n    left: -200px;\n    background: radial-gradient(circle, rgba(112, 148, 244, 0.1) 0%, rgba(112, 148, 244, 0) 70%);\n  }\n"], ["\n  position: absolute;\n  width: 600px;\n  height: 600px;\n  border-radius: 50%;\n  background: radial-gradient(circle, rgba(166, 92, 232, 0.1) 0%, rgba(166, 92, 232, 0) 70%);\n  z-index: -1;\n  \n  &.top-right {\n    top: -200px;\n    right: -200px;\n  }\n  \n  &.bottom-left {\n    bottom: -200px;\n    left: -200px;\n    background: radial-gradient(circle, rgba(112, 148, 244, 0.1) 0%, rgba(112, 148, 244, 0) 70%);\n  }\n"])));
var CookiePolicy = function () {
    return (React.createElement(PageContainer, null,
        React.createElement(BackgroundDecoration, { className: "top-right" }),
        React.createElement(BackgroundDecoration, { className: "bottom-left" }),
        React.createElement(MainContent, null,
            React.createElement(Title, null,
                "Cookie ",
                React.createElement("span", null, "Policy")),
            React.createElement(Subtitle, null, "Learn how we use cookies and similar technologies to improve your experience."),
            [
                {
                    title: "What Are Cookies",
                    content: "Cookies are small text files that are placed on your device when you visit our website. They help us provide you with a better experience and understand how you use our services."
                },
                {
                    title: "How We Use Cookies",
                    content: "We use cookies for essential functions, analytics, and to personalize your experience. This includes remembering your preferences and providing secure authentication."
                },
                {
                    title: "Types of Cookies We Use",
                    content: "Essential Cookies: Required for basic website functionality. Analytics Cookies: Help us understand how visitors interact with our website. Preference Cookies: Remember your settings and choices. Marketing Cookies: Used to deliver relevant advertisements and track campaign performance."
                },
                {
                    title: "Managing Your Cookie Preferences",
                    content: "You can control and/or delete cookies as you wish. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. However, if you do this, you may have to manually adjust some preferences every time you visit a site."
                },
                {
                    title: "Third-Party Cookies",
                    content: "Some of our pages may contain content from other sites, like embedded videos or social media links, which may set their own cookies. We do not have control over these third-party cookies."
                },
                {
                    title: "Updates to This Policy",
                    content: "We may update this Cookie Policy from time to time to reflect changes in our practices or for operational, legal, or regulatory reasons. We encourage you to periodically review this page for the latest information."
                }
            ].map(function (section, index) { return (React.createElement(ContentSection, { key: index, initial: { opacity: 0, y: 20 }, whileInView: { opacity: 1, y: 0 }, viewport: { once: true }, transition: { delay: index * 0.1 } },
                React.createElement(SectionTitle, null, section.title),
                React.createElement(SectionText, null, section.content))); })),
        React.createElement(FooterMain, null)));
};
export default CookiePolicy;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
